<template>
    <div>
        <v-card flat>
            <v-row class="pa-3" align="center" justify="end">
                <v-chip color="red" class="mx-1" small @click="resetFilters()">
                    <v-icon small left>filter_alt_off</v-icon> Clear
                    Filters
                </v-chip>

                <v-chip v-if="!loading" color="blue-grey" class="mx-1" small>
                    <v-icon small left>widgets</v-icon> {{ displayData.length }} Files
                </v-chip>
                <v-col cols="12" sm="4">
                    <v-text-field hide-details prepend-inner-icon="search" placeholder="Search" v-model="search" outlined dense
                    rounded clearable></v-text-field>
                </v-col>
               
            </v-row>

            <v-card-text>
                <v-row class="mx-2">
                 
                    <v-col cols="12" sm="2" class="text-center">
                        <b>File No.</b>

                    </v-col>
         
                    <v-col cols="12" sm="1">
                        <th class="text-center">Carrier <v-badge 
                                :content="filters['shippingLineName'] ? filters['shippingLineName'].length : false"
                                :value="filters['shippingLineName'] && filters['shippingLineName'].length > 0"
                                color="primary" offset-y="10">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                                small>filter_alt</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in headerOptions('shippingLineName')"
                                            :key="index">
                                            <v-list-item-action class="pr-0">
                                                <v-checkbox v-model="filters['shippingLineName']" :value="item.value"
                                                    hide-details dense @change="calculateFilteredResults()"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-badge></th>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <b>Vessel <v-badge 
                                :content="filters['vesselVoyage'] ? filters['vesselVoyage'].length : false"
                                :value="filters['vesselVoyage'] && filters['vesselVoyage'].length > 0" color="primary"
                                offset-y="10">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                                small>filter_alt</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in headerOptions('vesselVoyage')" :key="index">
                                            <v-list-item-action class="pr-0">
                                                <v-checkbox v-model="filters['vesselVoyage']" :value="item.value"
                                                    hide-details dense @change="calculateFilteredResults()"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-badge></b>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <b>POL  <v-badge 
                                :content="filters['portOfLoadValue'] ? filters['portOfLoadValue'].length : false"
                                :value="filters['portOfLoadValue'] && filters['portOfLoadValue'].length > 0" color="primary"
                                offset-y="10">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                                small>filter_alt</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in headerOptions('portOfLoadValue')" :key="index">
                                            <v-list-item-action class="pr-0">
                                                <v-checkbox v-model="filters['portOfLoadValue']" :value="item.value"
                                                    hide-details dense @change="calculateFilteredResults()"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-badge></b>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <b>Dest.  <v-badge 
                                :content="filters['finalDestinationValue'] ? filters['finalDestinationValue'].length : false"
                                :value="filters['finalDestinationValue'] && filters['finalDestinationValue'].length > 0" color="primary"
                                offset-y="10">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                                small>filter_alt</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in headerOptions('finalDestinationValue')" :key="index">
                                            <v-list-item-action class="pr-0">
                                                <v-checkbox v-model="filters['finalDestinationValue']" :value="item.value"
                                                    hide-details dense @change="calculateFilteredResults()"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-badge></b>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <th class="text-center">Shipper<v-badge 
                                :content="filters['shipperName'] ? filters['shipperName'].length : false"
                                :value="filters['shipperName'] && filters['shipperName'].length > 0" color="primary"
                                offset-y="10">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                                small>filter_alt</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in headerOptions('shipperName')" :key="index">
                                            <v-list-item-action class="pr-0">
                                                <v-checkbox v-model="filters['shipperName']" :value="item.value"
                                                    hide-details dense @change="calculateFilteredResults()"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-badge></th>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <th class="text-center">Consignee
                            <v-badge 
                                :content="filters['consigneeName'] ? filters['consigneeName'].length : false"
                                :value="filters['consigneeName'] && filters['consigneeName'].length > 0" color="primary"
                                offset-y="10">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                                small>filter_alt</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in headerOptions('consigneeName')" :key="index">
                                            <v-list-item-action class="pr-0">
                                                <v-checkbox v-model="filters['consigneeName']" :value="item.value"
                                                    hide-details dense @change="calculateFilteredResults()"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-badge>
                        </th>
                    </v-col>

                    <v-col cols="12" sm="1">
                        <th class="text-center">ETD
                            <v-badge 
                                :content="filters['etd'] ? filters['etd'].length : false"
                                :value="filters['etd'] && filters['etd'].length > 0" color="primary"
                                offset-y="10">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                                small>filter_alt</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in headerOptions('etd')" :key="index">
                                            <v-list-item-action class="pr-0">
                                                <v-checkbox v-model="filters['etd']" :value="item.value"
                                                    hide-details dense @change="calculateFilteredResults()"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-badge>
                        </th>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
                <div class="mt-1 pt-1">
                    <div class="container" v-if="!loading && displayData.length == 0">
                        <h3 style="color: grey">No Results Found</h3>
                    </div>
                    <v-virtual-scroll v-if="displayData.length > 0" :bench="10" :items="displayData" height="720px"
                        item-height="30">
                        <template v-slot:default="{ item }">
                            <v-row align="center" class="mx-1" style="cursor: pointer">
               
                                <!-- <v-col cols="12" sm="1">
                                    <v-chip small style="border:none; font-size: 10px" outlined @click="viewShipmentFile(item)">
                                        <v-icon left v-if="item.status == 'Draft'" small color="orange">edit_note</v-icon>
                                        <v-icon left v-else-if="item.status == 'Ready'" small color="blue">verified</v-icon>

                                        {{ item.fileNumber }}
                                    </v-chip>
                                    
                                </v-col> -->
                                <v-col cols="12" sm="2">
                                  
                                <v-tooltip v-if="item" top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip v-on="on" small style="border:none; font-size: 10px" outlined @click="viewShipmentFile(item)">
                                        <v-icon left v-if="item.status == 'Draft'" small color="orange">edit_note</v-icon>
                                        <v-icon left v-else-if="item.status == 'Ready'" small color="blue">verified</v-icon>
                                        <v-icon left v-if="item.type == 'LOGISTICS'" small color="green">mode_of_travel</v-icon>
                                        <v-icon left v-else-if="item.type == 'TRANSPORT'" small color="red">local_shipping</v-icon>
                                        <v-icon left v-else-if="item.type == 'DOCUMENTATION'" small color="secondary">article</v-icon>
                                        <v-icon left v-else-if="item.type == 'MONITORING'" small color="teal">thermostat</v-icon>
      
                                        {{ item.fileNumber }}
                                    </v-chip>
                                    </template>
                                     {{ item.type  }}
                                    </v-tooltip>
                                        
                                    
                                </v-col>
                                <!-- <v-col cols="12" sm="1">
                                    <v-tooltip v-if="item.type" top>
                                        <template v-slot:activator="{ on }">
                                        <v-chip v-on="on" small style="border:none; font-size: 10px" outlined>
                                        <v-icon left v-if="item.type == 'LOGISTICS'" small color="orange">mode_of_travel</v-icon>
                                        <v-icon left v-else-if="item.type == 'TRANSPORT'" small color="blue">local_shipping</v-icon>
                                        <v-icon left v-else-if="item.type == 'DOCUMENTATION'" small color="secondary">article</v-icon>
                                        <v-icon left v-else-if="item.type == 'MONITORING'" small color="teal">thermostat</v-icon>
                                    </v-chip>
                                    </template>
                                     {{ item.type  }}
                                    </v-tooltip>
                      
                                    
                                </v-col> -->
                                <v-col cols="12" sm="1">
                                    <v-chip v-if="item.shippingLine" small class="primaryText--text" outlined
                                        @click="addToFilter(item.shippingLineName, 'shippingLineName')"
                                        style="border: none; font-size: 11px">
                                        <v-avatar size="24"
                                            :color="item.shippingLine && item.shippingLine.logo ? 'white' : 'secondary'"
                                            left>
                                            <v-img v-if="item.shippingLine.logo"
                                                :src="item.shippingLine.logo" contain></v-img>
                                            <h3 v-else style="color: white">{{ item.shippingLineName.charAt(0) }}</h3>
                                        </v-avatar> {{ item.shippingLineName }}
                                    </v-chip>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <v-chip v-if="item.vesselVoyage" small class="primaryText--text" outlined
                                        @click="addToFilter(item.vesselVoyage, 'vesselVoyage')" style="border: none; font-size: 11px">
                                        {{ item.vesselVoyage }} <span class="ml-2" style="color: #9E9E9E">{{
                                            formatDate(item.etd)
                                        }}</span>
                                    </v-chip>
                                </v-col>
                                <v-col cols="12" sm="1">
                                    <v-tooltip top v-if="item.portOfLoadValue">
                                        <template v-slot:activator="{ on }">
                                            <v-chip outlined style="border: none; font-size: 11px" v-on="on" small
                                                @click="addToFilter(item.portOfLoadValue, 'portOfLoadValue')">
                                                <v-avatar size="20" left>
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                </v-avatar> {{ item.portOfLoadValue }}   
                                                <!-- <span class="ml-2" style="color: #9E9E9E">{{ formatDate(item.etd)
                                                        }}</span> -->
                                            </v-chip>
                                        </template>
                                        <span style="font-size: 12px">{{ item.portOfLoadCity }}</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-tooltip top v-if="item.finalDestinationValue">
                                        <template v-slot:activator="{ on }">
                                            <v-chip outlined style="border: none; font-size: 11px" v-on="on" small
                                                @click="addToFilter(item.finalDestinationValue, 'finalDestinationValue')">
                                                <v-avatar size="20" left>
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                </v-avatar> {{ item.finalDestinationValue }}   <span class="ml-2" style="color: #9E9E9E">{{ formatDate(item.eta)
                                                        }}</span>
                                            </v-chip>
                                        </template>
                                        <span style="font-size: 12px">{{ item.finalDestinationCity }}</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-tooltip top v-if="item.shipper">
                                        <template v-slot:activator="{ on }">
                                            <v-chip v-on="on" small class="primaryText--text" outlined
                                                @click="addToFilter(item.shipperName, 'shipperName')" style="border: none; font-size: 11px">
                                                <v-avatar size="20"
                                                    :color="item.shipper && item.shipper.logo ? 'white' : 'secondary'"
                                                    left>
                                                    <v-img v-if="item.shipper.logo" :src="item.shipper.logo"
                                                        contain></v-img>
                                                    <h3 v-else style="color: white">{{ item.shipperName.charAt(0) }}</h3>

                                                </v-avatar> {{ item.shipperName }}
                                            </v-chip>
                                        </template>
                                        {{ item.shipper.name }}
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="12" sm="1">
                                    <v-tooltip top v-if="item.consignee">
                                        <template v-slot:activator="{ on }">
                                            <v-chip small class="primaryText--text" outlined v-on="on"
                                                @click="addToFilter(item.consigneeName, 'consigneeName')"
                                                style="border: none; font-size: 11px">
                                                <v-avatar size="20"
                                                    :color="item.consignee && item.consignee.logo ? 'white' : 'secondary'"
                                                    left>
                                                    <v-img v-if="item.consignee.logo"
                                                        :src="item.consignee.logo" contain></v-img>
                                                    <h3 v-else style="color: white">{{ item.consigneeName.charAt(0) }}</h3>

                                                </v-avatar> {{ item.consigneeName }}
                                            </v-chip>
                                        </template>
                                        {{ item.consignee.name }}
                                    </v-tooltip>

                                </v-col>

                                <v-col cols="12" sm="1">
                                    <v-tooltip top v-if="item.etd">
                                        <template v-slot:activator="{ on }">
                                            <v-chip small class="primaryText--text" outlined v-on="on"
                                                @click="addToFilter(item.etd, 'etd')"
                                                style="border: none; font-size: 11px">
                                             {{ formatDate(item.etd) }}
                                            </v-chip>
                                        </template>
                                        {{ formatDate(item.etd) }}
                                    </v-tooltip>

                                </v-col>
                            </v-row>
                            <v-divider class="pt-1"></v-divider>
                        </template>
                    </v-virtual-scroll>
                </div>
            </v-card-text>
        </v-card>

        <v-dialog v-model="previewShipmentFile" width="90vw" :fullscreen="$vuetify.breakpoint.mobile">
            <ViewFile :key="previewKey" v-if="previewShipmentFile && selectedFile && selectedFile.id" @Cancelled="cancelFile"
                :fileId="selectedFile.id" @close="previewShipmentFile = false" />
        </v-dialog>
    </div>
</template>
<script>
import dateFormat from 'dateformat'
import ViewFile from '../SystemComponents/PreviewFileModal.vue'
export default {
    components: {
        ViewFile
    },
    data: ()=>({
        files: [],
        filters: {},
        filteredResults: [],
        fileQuery: null,
        fileTimeout: null,
        headers: [
            {
                text: 'Status',
                value: 'status'
            },
            {
                text: 'Vessel',
                value: 'vesselVoyage'
            },
            {
                text: 'Shipper',
                value: 'shipperName'
            },
            {
                text: 'Consignee',
                value: 'consigneeName'
            },

            {
                text: 'Carrier',
                value: 'shippingLineName'
            }, {
                text: 'POL',
                value: 'portOfLoadValue'
            }, {
                text: 'Destination',
                value: 'finalDestinationValue'
            }, {
                text: 'ETD',
                value: 'etd'
            }, {
                text: 'ETA',
                value: 'eta'
            }
        ],
        loading: false,
        previewShipmentFile: false,
        previewKey: 2000,
        search: null,
        selectedFile: null
    }),
    computed: {
        displayData() {
            let result = this.filteredResults
            if (this.search) {
                result = result.filter(x =>
                (x.shipperName && x.shipperName.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.fileNumber && x.fileNumber.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.portOfLoadValue && x.portOfLoadValue.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.portOfLoadCity && x.portOfLoadCity.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.finalDestinationValue && x.finalDestinationValue.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.finalDestinationCity && x.finalDestinationCity.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.shippingLineName && x.shippingLineName.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.vesselVoyage && x.vesselVoyage.toLowerCase().includes(this.search.toLowerCase())))
            }
            return result
        }
    },
    mounted() {
        this.resetFilters()
        this.getFiles()
    },
    methods: {
        addToFilter(value, field) {
            if (this.filters[field].includes(value)) {
                this.filters[field] = this.filters[field].filter(x => x != value)
            } else {
                this.filters[field].push(value)
            }
            this.calculateFilteredResults()
        },
        cancelFile(id){
            this.files = this.files.filter(x=>x.id != id)
            this.previewShipmentFile = false
            this.calculateFilteredResults()
        },
        calculateFilteredResults() {
            let result = [...this.files]
            let keys = Object.keys(this.filters)
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i]
                if (this.filters[key] && this.filters[key].length > 0) {
                        result = result.filter(x => this.filters[key].includes(x[key]))
                    }
            }
            this.filteredResults = result
        },
        formatDate(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd-mm-yyyy");
            }
            return result;
        },
        async getFiles() {
            if (this.fileTimeout) {
                clearTimeout(this.fileTimeout);
            }
            if (this.loading && this.fileQuery) {
                this.fileQuery.abort()
            }
            this.loading = true;
            this.fileTimeout = setTimeout(async () => {
                this.fileQuery = new AbortController();
                const signal = this.fileQuery.signal
                this.files = await this.$API.getDraftFiles({
                    // params: this.params,
                    signal
                });
                this.calculateFilteredResults()
                this.loading = false;
            }, 750);
        },
        headerOptions(value) {
                let result = [...new Set(
                    this.files.filter(x => x[value])
                        .map(x => ({ name: x[value], value: x[value] })))]
                let uniqueValues = [...new Set(result.map(x => x.value))]
                result = uniqueValues.map(x => (result.find(y => y.value === x)))
                if (value == 'status') {
                    result.push({ name: 'Unallocated', value: null })
                }
                return result

        },
        resetFilters(){
            this.filters = {}
            let keys = this.headers.map(x => x.value)
            keys.forEach(x => {
                this.filters[x] = []
            })
            this.calculateFilteredResults()
        },
        viewShipmentFile(file) {
            this.selectedFile = file
            this.previewKey++
            this.previewShipmentFile = true
        }
    }
}
</script>

<style scoped>
.container {
    width: 100%;
    height: 720px;
    margin: auto;
    /* outline: solid 1px black; */
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>