<template>
  <div>
    <v-card flat :loading="loading">
      <v-card-text>
        <v-row class="pa-3" align="center">
          <v-col cols="12" sm="6" class="mb-0 pb-0">
            <v-row>
              <v-col cols="12" class="mt-0 pt-0">
                <v-chip class="mx-1" small outlined v-for="(date, index) in quickSelectDates" :key="index"
                  :value="date.value" @click="selectDate(date.value)"
                  :color="params.selectedDate == date.value ? 'teal' : ''">
                  <v-icon left v-if="params.selectedDate == date.value" color="teal" small>check</v-icon>
                  {{ date.text }}</v-chip>
                <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip color="blue" class="mx-1" outlined v-bind="attrs" v-on="on" small><v-icon small
                        left>date_range</v-icon>{{ dateText }}
                      <v-icon small right>expand_more</v-icon></v-chip>
                  </template>
                  <v-date-picker v-model="params.dates" range color="primary" show-week scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary"> Cancel </v-btn>
                    <v-btn text color="primary" @click="setDateFilter">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>

                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-chip small class="primaryText--text mx-1" color="blue-grey" v-on="on">
                      <v-icon small left>event</v-icon> {{ params.dateType
                      }}<v-icon small right>expand_more</v-icon>
                    </v-chip>
                  </template>
                  <v-list style="overflow: hidden" dense>
                    <v-list-item v-for="(mileType, index) in milestoneTypes" :key="index"
                      @click="changeDateType(mileType.value)">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ mileType.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-chip id="team-select-one" small class="mx-1" :color="selectedTeams.includes(team.id) ? team.colour : 'darkgrey'
      " v-for="team in allTeams" :key="team.id" @click="selectTeam(team.id)">
                  <v-icon small left v-if="selectedTeams.includes(team.id)">check</v-icon>
                  {{ team.name }}</v-chip>
                <v-chip id="team-select-all" outlined color="success" small
                  v-if="selectedTeams.length < allTeams.length" @click="selectAllTeams()">Select All</v-chip>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>

          <v-btn v-if="settingsChange" color="blue" small rounded outlined @click="saveCustomHeaders()"
            :loading="loadingSaveCustom">
            <v-icon small left>save</v-icon> Save Layout</v-btn>

          <v-col cols="12" sm="6" class="text-right mb-0 pb-0">
            <v-chip style="height: 40px" outlined small class="pr-1 text-center">


              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-chip small class="primaryText--text mx-1" :color="'blue'" v-on="on" style="border: none">
                    Actions<v-icon small right>expand_more</v-icon>
                  </v-chip>
                </template>
                <v-list style="overflow: hidden" dense>

                  <v-list-item style="height: 35px" @click="downloadBookingData"
                    :disabled="selectedBookings.length == 0">
                    <v-list-item-action>
                      <v-icon color="grey">save_alt</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Download Booking Data
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="height: 35px" @click="uploadContainerDetails">
                    <v-list-item-action>
                      <v-icon color="grey">cloud_upload</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Upload Container Details
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>


                  <v-list-item style="height: 35px" @click="q67CommentModal = true"
                    :disabled="q67Disabled">
                    <v-list-item-action>
                      <v-icon color="grey">article</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Bulk Create Q67 </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="height: 35px" @click="viewEQ67" v-if="$store.state.user.tagId === 1"
                    :disabled="q67Disabled">
                    <v-list-item-action>
                      <v-icon color="grey">article</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Bulk Create EQ67 </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <!-- <v-list-item style="height: 35px" @click="getLoadOutMailData()"
                    :disabled="selectedBookings.length === 0" v-if="!hideLoadoutMail">
                    <v-list-item-action>
                      <v-icon color="grey">email</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Load out instructions </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->

                  <v-list-item style="height: 35px" @click="validateDispatchPalletQauntity()"
                    :disabled="disalbedDispatch" v-if="!hideLoadoutMail">
                    <v-list-item-action>
                      <v-icon color="grey">send_time_extension</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Create QX Dispatches </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list> 

              </v-menu>

              <v-chip color="blue-grey" small class="mr-1 pr-3">
                <span style="padding-right: 10px">
                  {{ filteredResults.length }} Containers</span>
              </v-chip>

              <v-tooltip top open-delay="500">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-menu offset-y nudge-right :close-on-content-click="false"
                      style="height: 20vh; margin-right: 8vw">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon><v-icon>view_week</v-icon></v-btn>
                      </template>
                      <v-card style="
                            background: var(--v-modal-base) !important;
                            border-radius: 20px;
                          ">
                        <v-card-text>
                          <v-list style="
                                overflow: hidden;
                                column-count: 3;
                                width: 400px;
                                height: fit-content;
                              ">
                            <v-checkbox v-for="(item, index) in headers.filter(
      (x) => x.value != 'action'
    )" :key="index" v-model="item.show" @change="toggleColumn(item)" dense :label="item.text"
                              hide-details></v-checkbox>
                          </v-list>
                          <v-row class="pt-5 pb-4" style="overflow-y: hidden">
                            <v-col cols="12" md="12" sm="12" lg="12" class="d-flex justify-center">
                              <v-btn @click="saveCustomHeaders" :loading="loadingSaveCustom" outlined
                                color="primary">SAVE</v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </template>
                Customize Layout
              </v-tooltip>
              <v-text-field placeholder="Search" class="mb-1" hide-details rounded clearable dense style="width: 300px"
                v-model="search"></v-text-field>
            </v-chip>
            <v-row class="my-3" :key="updateFilterValue">
              <div :key="updateFilterValue">
                <v-chip color="red" class="mx-1" small @click="resetFilters()" v-if="filteredValues.length > 0">
                  <v-icon small left>filter_alt_off</v-icon> Clear Filters
                </v-chip>
                <v-chip class="mx-1" close small @click:close="
      addToFilter(filterValue.value, filterValue.field)
      " v-for="(filterValue, index) in filteredValues" :key="index">{{ filterValue.value }}</v-chip>
              </div>
            </v-row>
          </v-col>
        </v-row>
        <v-card-text style="max-width: 100%; overflow-x: auto" class="my-0">
          <div :style="{ width: tableWidth }" v-if="displayData.length > 0">
            <table :width="'100%'">
              <tr class="mx-0 px-0">
                <draggable v-model="selectedHeaders" tag="tr" :style="{ cursor: columnDrag ? 'grabbing' : 'grab' }"
                  :animation="200" @change="updateUserLayout()" ghost-class="ghost-card" @start="columnDrag = true"
                  @end="columnDrag = false">
                  <td class="text-center mx-0 px-0" style="
                        cursor: grab;
                        border-right: 1px solid grey;
                        min-height: 20px;
                        min-width: 20px;
                      " v-for="(header, index) in selectedHeaders" :key="index" :width="header.width ?? '150px'">
                    <div>
                      <v-row align="center" justify="center" class="py-2">
                        <v-checkbox v-if="header.value == 'actions'" :indeterminate="selectedBookings.length > 0 &&
      selectedBookings.length < filteredResults.length
      " hide-details v-model="selectAllBookings" dense class="mx-0 px-0 mb-2"></v-checkbox>
                        <b style="font-size: 12px">{{ header.text }}</b>
                        <v-btn v-if="!header.disableSort" class="ml-1" icon x-small @click="changeSortBy(header.value)">
                          <v-icon v-if="sortBy != header.value" small color="grey">arrow_upward</v-icon>
                          <v-icon v-else-if="sortDesc" small>arrow_downward</v-icon>
                          <v-icon v-else small>arrow_upward</v-icon>
                        </v-btn>
                        <v-badge v-if="!header.disableFilter" :content="filters[header.value]
      ? filters[header.value].length
      : false
      " :value="filters[header.value] &&
      filters[header.value].length > 0
      " color="primary" offset-y="10">
                          <v-menu offset-y left :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" x-small color="grey" icon><v-icon
                                  small>filter_alt</v-icon></v-btn>
                            </template>
                            <v-card style="
                                  background: var(--v-modal-base) !important;
                                  border-radius: 20px;
                                ">
                              <v-card-text>
                                <v-list dense style="
                                      background: var(--v-modal-base) !important;
                                      border-radius: 20px;
                                    ">
                                  <v-text-field hide-details rounded dense outlined clearable
                                    prepend-inner-icon="search" placeholder="Search" autofocus
                                    v-model="searchHeaders[header.value]" @input="updateSearchKey()"></v-text-field>
                                  <v-list dense style="
                                        max-height: 50vh;
                                        overflow-y: auto;
                                        background: var(
                                          --v-modal-base
                                        ) !important;
                                      " :key="searchKey">
                                    <v-list-item v-for="(item, index) in headerOptions(
      header.value
    )" :key="index">
                                      <v-list-item-action class="pr-0">
                                        <v-checkbox v-model="filters[header.value]" :value="item.value" hide-details
                                          dense @change="calculateFilteredResults()"></v-checkbox>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ item.name }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="headerOptions(header.value).length == 0
      ">
                                      <v-list-item-content class="text-center">
                                        <span style="font-size: 12px">No results found</span>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </v-badge>
                      </v-row>
                    </div>
                  </td>
                </draggable>
              </tr>
            </table>
            <v-divider></v-divider>
            <div class="mt-1 pt-1 px-1" :style="{ width: '100%' }" v-if="displayData.length > 0">
              <v-virtual-scroll v-if="displayData.length > 0" :bench="10" :items="displayData"
                :height="calculatedHeight" :width="'100%'" item-height="35" class="px-1">
                <template v-slot:default="{ item }">
                  <div class="text-center my-0 py-0" :style="{
      display: 'flex',
      'flex-direction': 'row',
      width: '100%',
      'background-color':
        selectedBookingItem && selectedBookingItem.id == item.id
          ? 'rgba(12, 97, 24, 0.6)'
          : 'transparent',
    }" @contextmenu="viewItem($event, item)">
                    <td v-for="(header, index) in selectedHeaders" :key="index"
                      :style="{ width: header.width ?? '150px' }" class="text-center mt-0 mb-0 pb-0">
                      <div v-if="header.value == 'actions'">
                        <v-row align="center" justify="center" class="py-1">
                          <v-checkbox hide-details dense :value="selectedBookings
      .map((x) => x.id)
      .includes(item.id)
      " @change="selectBooking(item)" class="mb-2 mx-0 px-0"></v-checkbox>

                          <v-tooltip top v-if="item.booking">
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="teal" v-on="on" @click="viewBooking(item.booking)">
                                <v-icon small>launch</v-icon>
                              </v-btn>
                            </template>
                            View booking {{ item.booking.orderNo }}
                          </v-tooltip>

                          <v-tooltip top v-if="item.shipmentFile">
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="indigo" v-on="on" @click="viewShipmentFile(item.shipmentFile)">
                                <v-icon small>description</v-icon>
                              </v-btn>
                            </template>
                            View file {{ item.shipmentFile.fileNumber }}
                          </v-tooltip>
                        </v-row>
                      </div>
                      <div v-if="header.value == 'state'" class="text-center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn :color="statusColor(item.state)" small icon v-on="on"
                              @click="addToFilter(item.state, 'state')">
                              <v-icon>{{ statusIcon(item.state) }}</v-icon>
                            </v-btn>
                          </template>
                          <span style="font-size: 12px">{{
      $Format.capitalizeFirstLetter(item.state)
    }}</span>
                        </v-tooltip>
                      </div>

                      <div v-else-if="header.value == 'loadoutStatus'" class="text-center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn :color="item.loadoutStatus ? 'green' : 'red'" small icon v-on="on"
                              @click="addToFilter(item.loadoutStatus, 'loadoutStatus')">
                              <v-icon>{{ item.loadoutStatus
      ? 'check_circle'
      : 'highlight_off' }}</v-icon>
                            </v-btn>
                          </template>
                          <!-- <span style="font-size: 12px">{{
      "Loadout"
    }}</span> -->
                        </v-tooltip>
                      </div>

                      <div v-else-if="header.value === 'dispatchStatus'">
                        <v-chip v-if="item[header.value]" small outlined
                          @click="addToFilter(item[header.value], header.value)"
                          :color="statusColor(item[header.value])">
                          <!-- {{ item[header.value] }}
     -->
                          {{ $Format.capitalizeFirstLetter(item[header.value]) }}
                        </v-chip>
                      </div>

                      <div v-else-if="header.value == 'packingListStatus'" class="text-center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn :color="item.packingListStatus ? 'green' : 'red'" small icon v-on="on"
                              @click="addToFilter(item.packingListStatus, 'packingListStatus')">
                              <v-icon>{{ item.packingListStatus
      ? 'check_circle'
      : 'highlight_off' }}</v-icon>
                            </v-btn>
                          </template>
                          <!-- <span style="font-size: 12px">{{
      "Packing List Document"
    }}</span> -->
                        </v-tooltip>
                      </div>
                      <div v-else-if="header.value == 'userFullName'" class="text-center">
                        <v-tooltip top v-if="item.booking.user">
                          <template v-slot:activator="{ on }">
                            <v-avatar style="cursor: pointer" size="24" class="ml-1" v-on="on"
                              :color="item.booking.user.avatar ? 'white' : 'secondary'" @click="
      addToFilter(item.booking.user.firstname, 'userFullName')
      ">
                              <v-img v-if="item.booking.user.avatar" contain :src="item.booking.user.avatar"></v-img>
                              <h4 v-else>
                                {{ item.booking.user.firstname.charAt(0) }}
                              </h4>
                            </v-avatar>
                          </template>
                          <span style="font-size: 12px">{{ item.booking.user.firstname }}
                            {{ item.booking.user.surname }}</span>
                        </v-tooltip>
                      </div>

                      <div v-else-if="header.value == 'orderNo'" class="text-center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn small icon v-on="on" @click="addToFilter(item.orderNo, 'orderNo')">
                              <v-icon>{{ bookingTypeIcon(item.type) }}</v-icon>
                              {{ item.orderNo }}
                            </v-btn>

                          </template>
                          <span style="font-size: 12px">{{
      $Format.capitalizeFirstLetter(item.booking.type)
    }}</span>
                        </v-tooltip>
                      </div>

                      <div v-else-if="header.value == 'vesselVoyage'" class="text-left">
                        <v-tooltip top v-if="item.vesselVoyage">
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small class="primaryText--text" outlined @click="
      addToFilter(item.vesselVoyage, 'vesselVoyage')
      " style="border: none; font-size: 11px">
                              <v-icon small left v-if="item.isTransshipment" color="orange">airline_stops</v-icon>
                              <v-icon v-else small left color="grey">navigation</v-icon>
                              <span>{{ item.vesselVoyage }}</span>
                            </v-chip>
                          </template>
                          <span>{{ item.vesselVoyage }}:
                            <span v-if="item.isTransshipment">Transshipment</span>
                            <span v-else>Direct</span>
                          </span>
                        </v-tooltip>
                      </div>
                      <div v-else-if="header.value == 'features'" class="text-center">
                        <v-tooltip top v-if="item.temporaryProducts.some(
      (product) => product.product === 'Genset'
    )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#D6D46D"><v-icon
                                small>bolt</v-icon></v-btn>
                          </template>
                          <span>Genset Required</span>
                        </v-tooltip>


                        <v-tooltip top v-if="item.temporaryProducts.some(
      (product) =>
        product.product === 'Fumigation'
    )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#F1DBBF"><v-icon
                                small>cleaning_services</v-icon></v-btn>
                          </template>
                          <span>Fumigations Required</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some(
      (product) =>
        product.product === 'Void Plugs'
    )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#e05b0d"><v-icon
                                small>power</v-icon></v-btn>
                          </template>
                          <span>Void Plugs</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some(
      (product) =>
        product.product === 'Blast Freezing'
    )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#13a7d4"><v-icon
                                small>ac_unit</v-icon></v-btn>
                          </template>
                          <span>Blast Freezing</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some(
      (product) =>
        product.product === 'Monitoring Service'
    )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#99B080"><v-icon
                                small>desktop_windows</v-icon></v-btn>
                          </template>
                          <span>
                            {{
      item.temporaryProducts.find(
        (product) => product.product === 'Monitoring Service'
      ).variation + ' ' + 'Service' + ' ' + 'Required'
    }}
                          </span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some(
        (product) =>
          product.product === 'ContainerLiners'
      )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#EEF296"><v-icon
                                small>settings_overscan</v-icon></v-btn>
                          </template>
                          <span>
                            {{
      'Container Liners' + ' ' + 'Required'
    }}
                          </span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some(
        (product) =>
          product.product === 'SmartFresh'
      )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#FFBFFF"><v-icon
                                small>smart_screen</v-icon></v-btn>
                          </template>
                          <span>
                            {{
      'Smart Fresh' + ' ' + 'Required'
    }}
                          </span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some(
        (product) =>
          product.product === 'SteriInsurance'
      )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#EEF296"><v-icon
                                small>emergency</v-icon></v-btn>
                          </template>
                          <span>
                            {{
      'Steri Insurance' + ' ' + 'Required'
    }}
                          </span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some(
        (product) => product.product === 'TelemPlus'
      )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#18e00d"><v-icon
                                small>router</v-icon></v-btn>
                          </template>
                          <span>
                            Devices Required:
                            <template v-if="item.temporaryProducts.some(
      (product) =>
        product.product === 'TelemPlus'
    )
      ">
                              <ul>
                                <li v-for="(
                                          [variation, count], index
                                        ) in Object.entries(
      item.temporaryProducts
        .filter(
          (product) =>
            product.product === 'TelemPlus'
        )
        .reduce((acc, product) => {
          if (!acc[product.variation]) {
            acc[product.variation] = 0;
          }
          acc[product.variation] +=
            product.count;
          return acc;
        }, {})
    )" :key="index">
                                  {{ count }}X{{ variation }}
                                </li>
                              </ul>
                            </template>
                          </span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some( (product) => product.product === 'Otflow' ) ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#6A9C89"><v-icon
                                small>air</v-icon></v-btn>
                          </template>
                          <span>Otflow Required</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some( (product) => product.product === 'Zoono' ) ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#5b93c7"><v-icon
                                small>sanitizer</v-icon></v-btn>
                          </template>
                          <span>Zoono Required</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some( (product) => product.product === 'Dunnage Bags' ) ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#F1DBBF"><v-icon
                                small>shopping_bag</v-icon></v-btn>
                          </template>
                          <span>Dunnage Bags Required</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some(
      (product) =>
        product.product === 'Late Arrival'
    )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#BB2525"><v-icon
                                small>watch_later</v-icon></v-btn>
                          </template>
                          <span>Late Arrival Required : {{ item.temporaryProducts.find(
      (product) => product.product === 'Late Arrival').variation
                            }}</span>
                        </v-tooltip>




                        <!--            -->
                        <v-tooltip top v-if="item.temporaryProducts.some(
      (product) =>
        product.product === 'Latches'
    )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#508D69"><v-icon
                                small>pivot_table_chart</v-icon></v-btn>
                          </template>
                          <span>Latches Required</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some(
      (product) =>
        product.product === 'Ratches'
    )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#EA906C"><v-icon
                                small>settings_input_component</v-icon></v-btn>
                          </template>
                          <span>Ratches Required</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some(
      (product) =>
        product.product === 'Stabling bars'
    )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#5F6F52"><v-icon
                                small>video_stable</v-icon></v-btn>
                          </template>
                          <span>Stabling bars Required</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some(
      (product) =>
        product.product === 'Kraft paper'
    )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#C5E898"><v-icon
                                small>note</v-icon></v-btn>
                          </template>
                          <span>Kraft paper Required</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.temporaryProducts.some(
      (product) =>
        product.product === 'Absorb Bags'
    )
      ">
                          <template v-slot:activator="{ on }">
                            <v-btn icon outlined small class="mx-1" v-on="on" color="#DCBFFF"><v-icon
                                small>backpack</v-icon></v-btn>
                          </template>
                          <span>Absorb Bags Required</span>
                        </v-tooltip>
                      </div>
                      <div v-else-if="header.value == 'portOfLoadValue'" class="text-center">
                        <v-tooltip top v-if="item.portOfLoadValue">
                          <template v-slot:activator="{ on }">
                            <v-chip outlined style="border: none; font-size: 11px" v-on="on" small @click="
      addToFilter(
        item.portOfLoadValue,
        'portOfLoadValue'
      )
      ">
                              <v-avatar size="20" left>
                                <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
      .substring(0, 2)
      .toLowerCase()}.svg`"></v-img>
                              </v-avatar>
                              {{ item.portOfLoadValue }}
                            </v-chip>
                          </template>
                          <span style="font-size: 12px">{{
      item.portOfLoadCity
    }}</span>
                        </v-tooltip>
                      </div>
                      <div v-else-if="header.value == 'finalDestinationValue'" class="text-center">
                        <v-tooltip top v-if="item.finalDestinationValue">
                          <template v-slot:activator="{ on }">
                            <v-chip outlined style="border: none; font-size: 11px" v-on="on" small @click="
      addToFilter(
        item.finalDestinationValue,
        'finalDestinationValue'
      )
      ">
                              <v-avatar size="20" left>
                                <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
      .substring(0, 2)
      .toLowerCase()}.svg`"></v-img>
                              </v-avatar>
                              {{ item.finalDestinationValue }}
                            </v-chip>
                          </template>
                          <span style="font-size: 12px">{{
      item.finalDestinationCity
    }}</span>
                        </v-tooltip>
                      </div>
                      <div v-else-if="header.value == 'isConfirmedBooking'" class="text-center">
                        <v-btn small icon :color="item.isConfirmedBooking ? 'success' : 'red'" @click="
      addToFilter(
        item.isConfirmedBooking,
        'isConfirmedBooking'
      )
      ">
                          <v-icon v-if="item.isConfirmedBooking" small>check_circle_outline</v-icon>
                          <v-icon v-else color="red" small>cancel</v-icon>
                        </v-btn>
                      </div>

                      <div v-else-if="header.value == 'preadvise'" class="text-center">
                        <v-btn small icon :color="item.preadvise ? 'success' : 'red'"
                          @click="addToFilter(item.preadvise, 'preadvise')">
                          <v-icon v-if="item.preadvise" small>check_circle_outline</v-icon>
                          <v-icon v-else color="red" small>cancel</v-icon>
                        </v-btn>
                      </div>

                      <div v-else-if="header.value == 'consigneeName'" class="text-left">
                        <v-tooltip top v-if="item.consignee">
                          <template v-slot:activator="{ on }">
                            <v-chip v-if="item.consignee" small class="primaryText--text" outlined @click="
      addToFilter(item.consigneeName, 'consigneeName')
      " v-on="on" style="border: none">
                              <v-avatar size="24" :color="item.consignee && item.consignee.logo
      ? 'white'
      : 'secondary'
      " left>
                                <v-img style="" v-if="item.consignee.logo" :src="item.consignee.logo" contain></v-img>
                                <h3 v-else style="color: white">
                                  {{ item.consigneeName.charAt(0) }}
                                </h3>
                              </v-avatar>
                              {{ item.consigneeName }}
                            </v-chip>
                          </template>
                          <span>{{ item.consignee.name }}</span>
                        </v-tooltip>
                      </div>
                      <div v-else-if="header.value == 'transporterName'"
                        :class="item.transporter ? 'text-left' : 'text-center'">
                        <v-tooltip top v-if="item.transporter">
                          <template v-slot:activator="{ on }">
                            <v-chip v-if="item.transporter" small class="primaryText--text" v-on="on" outlined @click="
      addToFilter(
        item.transporterName,
        'transporterName'
      )
      " style="border: none">
                              <v-avatar size="20" :color="item.transporter && item.transporter.logo
      ? 'white'
      : 'secondary'
      " left>
                                <v-img v-if="item.transporter.logo" :src="item.transporter.logo" contain></v-img>
                                <h3 v-else style="color: white">
                                  {{ item.transporterName.charAt(0) }}
                                </h3>
                              </v-avatar>
                              {{ item.transporterName }}
                            </v-chip>
                          </template>
                          <span>{{ item.transporter.name }}</span>
                        </v-tooltip>
                        <v-btn color="grey" small icon v-else @click="assignContainers([item])"><v-icon
                            small>add_circle_outline</v-icon></v-btn>
                      </div>

                      <div v-else-if="header.value == 'customerName'" class="text-left">
                        <v-tooltip top v-if="item.customer">
                          <template v-slot:activator="{ on }">
                            <v-chip v-if="item.customer" small class="primaryText--text" v-on="on" outlined @click="
      addToFilter(item.customerName, 'customerName')
      " style="border: none">
                              <v-icon class="mr-2" small left v-if="item.clientStatus"
                                :color="getClientStatusColor(item.clientStatus)">fiber_manual_record</v-icon>
                              <v-avatar size="20" :color="item.customer && item.customer.logo
      ? 'white'
      : 'secondary'
      " left>
                                <v-img v-if="item.customer.logo" :src="item.customer.logo" contain></v-img>
                                <h3 v-else style="color: white">
                                  {{ item.customerName.charAt(0) }}
                                </h3>
                              </v-avatar>
                              {{ item.customerName }}
                            </v-chip>
                          </template>
                          <span>{{ item.customer.name }}
                            <span v-if="item.clientStatus">
                              - Status: {{ item.clientStatus }}</span></span>
                        </v-tooltip>
                      </div>
                      <div v-else-if="header.value == 'shipperName'" class="text-left">
                        <v-tooltip top v-if="item.shipper">
                          <template v-slot:activator="{ on }">
                            <v-chip v-if="item.shipper" small class="primaryText--text" v-on="on" outlined @click="
      addToFilter(item.shipperName, 'shipperName')
      " style="border: none">
                              <v-avatar size="20" :color="item.shipper && item.shipper.logo
      ? 'white'
      : 'secondary'
      " left>
                                <v-img v-if="item.shipper.logo" :src="item.shipper.logo" contain></v-img>
                                <h3 v-else style="color: white">
                                  {{ item.shipperName.charAt(0) }}
                                </h3>
                              </v-avatar>
                              {{ item.shipperName }}
                            </v-chip>
                          </template>
                          <span>{{ item.shipper.name }}</span>
                        </v-tooltip>
                      </div>


                      <div v-else-if="header.value == 'shippingLineName'" class="text-left">
                        <v-chip v-if="item.shippingLine" small class="primaryText--text" outlined @click="
      addToFilter(
        item.shippingLineName,
        'shippingLineName'
      )
      " style="border: none">
                          <v-avatar size="24" :color="item.shippingLine && item.shippingLine.logo
      ? 'white'
      : 'secondary'
      " left>
                            <v-img v-if="item.shippingLine.logo" :src="item.shippingLine.logo" contain></v-img>
                            <h3 v-else style="color: white">
                              {{ item.shippingLineName.charAt(0) }}
                            </h3>
                          </v-avatar>
                          {{ item.shippingLineName }}
                        </v-chip>
                      </div>
                      <div v-else-if="header.value == 'transportStatus'">

                        <v-chip v-if="item[header.value]" small outlined @click="
      addToFilter(item[header.value], header.value)
      " :color="transportStatusColor(item[header.value])">
                          {{ item[header.value] }}
                        </v-chip>
                      </div>
                      <div v-else-if="header.value == 'stockStatus'">
                        <v-chip v-if="item[header.value]" small outlined @click="
      addToFilter(item[header.value], header.value)
      " :color="orderItemStatusColor(item[header.value])">
                          {{ item[header.value] }}
                        </v-chip>
                      </div>

                      <div v-else-if="header.value == 'status'">

                        <v-chip v-if="item[header.value]" small outlined @click="
      addToFilter(item[header.value], header.value)
      " :color="containerStatusColor(item[header.value])">
                          {{ item[header.value] }}
                        </v-chip>
                      </div>

                      <div v-else-if="header.value == 'loadingDate'">
                        <v-chip v-if="item[header.value]" small class="primaryText--text" outlined
                          @click="addToFilter(item[header.value], header.value)" style="border: none">
                          {{ formatDate(item[header.value]) }}
                        </v-chip>
                      </div>
                      <div v-else-if="header.value == 'etd'">
                        <v-chip v-if="item[header.value]" small class="primaryText--text" outlined
                          @click="addToFilter(item[header.value], header.value)" style="border: none">
                          {{ formatDate(item[header.value]) }}
                        </v-chip>
                      </div>


                      <div v-else-if="header.value == 'eta'">
                        <v-chip v-if="item[header.value]" small class="primaryText--text" outlined
                          @click="addToFilter(item[header.value], header.value)" style="border: none">
                          {{ formatDate(item[header.value]) }}
                        </v-chip>
                      </div>

                      <div v-else-if="header.value == 'movementType'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small class="primaryText--text" outlined @click="
      addToFilter(item.movementType, header.value)
      " style="border: none">
                              <v-avatar :color="item.movementType == 'EXPORT'
      ? 'deep-orange'
      : 'blue'
      " left>
                                <h4 style="color: white">
                                  {{ item.movementType.charAt(0) }}
                                </h4>
                              </v-avatar>
                              {{ item.orderNo }}
                              <v-icon small right color="grey"
                                v-if="item.organisationId != $store.state.currentOrg.id">domain_disabled</v-icon>
                            </v-chip>
                          </template>
                          <span>
                            <span v-if="item.organisationId != $store.state.currentOrg.id">EXTERNAL: </span>
                            {{
      $Format.capitalizeFirstLetter(item.movementType)
    }}
                            Booking

                          </span>
                        </v-tooltip>
                      </div>

                      <div v-else>
                        <v-chip v-if="item[header.value]" small class="primaryText--text" outlined
                          @click="addToFilter(item[header.value], header.value)" style="border: none">
                          {{ item[header.value] }}
                        </v-chip>
                      </div>
                    </td>
                  </div>

                  <v-divider class="my-0 py-0"></v-divider>
                </template>
              </v-virtual-scroll>
            </div>
          </div>
          <div class="container" v-if="!loading && displayData.length == 0">
            <h3 style="color: grey">No Results Found</h3>
          </div>
          <div class="container" v-else-if="loading && displayData.length == 0">
            <valhalla-loading height="500px" />
          </div>
        </v-card-text>
      </v-card-text>
    </v-card>
    <v-dialog v-model="previewModal" width="1200px" :fullscreen="fullscreen || $vuetify.breakpoint.mobile">
      <v-card v-if="item && item.name">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ item.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="downloadDocument(item)" icon><v-icon>download</v-icon></v-btn>
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon text @click="(previewModal = false), (item = {})">X</v-btn>
        </v-toolbar>
        <v-card-text :style="{
      'max-height': fullscreen ? '90vh' : '75vh',
      'overflow-y': 'auto',
    }">
          <v-row>
            <v-col cols="12" :loading="imageLoading">
              <div v-if="item.type && item.type.includes('image/')">
                <v-img :src="item.url" contain style="max-height: 70vh" @load="imageLoading = false">
                </v-img>
              </div>
              <div v-else-if="item.type == 'application/pdf'">
                <v-row justify="center">
                  <v-col cols="12" sm="9" class="text-center" v-if="item.url">
                    <div ref="pdfBox">
                      <VuePdfEmbed :width="fullscreen ? 1200 : 900" :source="item.url" @loaded="imageLoading = false" />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-container v-else fill-height>
                <v-row justify="center" height="100%">
                  <div class="text-center">
                    <span>This file is in a format that cannot be previewed.</span>
                    <br /><br />
                    <v-btn color="primary" style="text-transform: none" @click="downloadDocument(item)">
                      <v-icon class="mr-1">download</v-icon> Download</v-btn>
                  </div>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="q67CommentModal" width="500px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Q67</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="(q67CommentModal = false), (q67Comment = null)"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="6">
              <v-text-field outlined dense label="PPECB N Code" v-model="q67Request.ppecbNcode"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select outlined dense label="Charge Party" v-model="q67Request.ppecbInv" :items="[
      'shipper',
      'forwarder',
      'onBehalfShipper',
      'onBehalfForwarder',
    ]" item-text="name" item-value="name"></v-select>
            </v-col>
          </v-row>
          <v-textarea v-model="q67Comment" outlined placeholder="Addtional Comments (optional)"></v-textarea>
          <v-row justify="center">
            <v-btn @click="(q67Modal = true), (q67CommentModal = false)">Next</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="q67Modal" width="750px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Bulk Create Q67 </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn text @click="
      (q67Modal = false),
      (finishedProcessingQ67 = false),
      (finishedProcessingMergedQ67 = false),
      (bulkQ67 = null)
      ">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn v-if="!finishedProcessingMergedQ67" color="teal" small  rounded @click="startProcessingMergedQ67()"
                :loading="processingMergedQ67">Generate Merged Q67</v-btn>
              <v-row v-else-if="finishedProcessingMergedQ67 && bulkQ67">
                <v-col cols="12" class="mb-0">
                  <v-btn small rounded @click="downloadDocument(bulkQ67.q67)" :loading="processingMergedQ67">Download
                    Merged
                    PDF</v-btn>
                </v-col>
                <v-col cols="12" class="mt-0">
                  <v-btn small rounded @click="downloadDocument(bulkQ67.q67Excel)"
                    :loading="processingMergedQ67">Download
                    Merged Excel</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn v-if="!finishedProcessingQ67" color="blue" small rounded @click="startProcessingQ67()"
                :loading="processingQ67">Generate Individually</v-btn>
              <v-btn v-else-if="finishedProcessingQ67" :loading="downloadingBulkQ67" @click="bulkDownloadQ67()" small
                class="mx-1" color="blue darken-2">
                <v-icon small left>download</v-icon> Download All
              </v-btn>
            </v-col>
          </v-row>
          <v-list style="max-height: 50vh; overflow-y: auto" :key="q67UpdateKey" dense>
            <v-list-item v-for="item in selectedBookings" :key="item.id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.booking.orderNo }}
                  <!-- {{ item.ctoNo }} -->
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.q67">
                  Version: {{ item.q67.version }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="item.errorMessage">
                  Error: {{ item.errorMessage }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-row justify="center">
                  <v-tooltip top v-if="item.q67">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small @click="viewDocument(item.q67)" v-on="on">
                        <v-icon color="blue" small>launch</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">View Document</span>
                  </v-tooltip>
                  <v-tooltip top v-if="item.q67">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small @click="downloadDocument(item.q67)" v-on="on">
                        <v-icon small>download</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">Download Q67</span>
                  </v-tooltip>
                  <v-tooltip top v-if="item.q67Excel">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small @click="downloadDocument(item.q67Excel)" v-on="on" color="green">
                        <v-icon small>browser_updated</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">Download Excel Q67</span>
                  </v-tooltip>
                  <v-icon v-if="item.errorMessage" color="red">error</v-icon>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="loadoutModal" width="1200px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-card-title>
          {{ 'Load out Mail' }}
          <v-spacer></v-spacer>

          <v-btn text @click="emailLoadout()" :loading="mailingLoadoutInstr" :disable='disabledLoadoutMail'>
            <v-icon color="secondary">email</v-icon>
          </v-btn>
          <v-btn text @click="loadoutModal = false; selectedBookings = []">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" v-for="(stock, index) in loadoutEmailData" :key="index">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header style="background-color: var(--v-background-base) !important">
                    <v-row no-gutters align="center">
                      <v-col>
                        {{ stock.poiName }}
                      </v-col>
                      <v-col cols="auto">
                        <v-btn icon>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: var(--v-background-base) !important">
                    <!-- Add any additional content for each expansion panel here -->
                    <v-card flat>
                      <v-row>
                        <v-text-field :label="` ${stock.poiName}: Email Addresses`" outlined
                          hint="Please separate email addresses with a comma (,)" v-model="stock.emailAddresses">
                        </v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field label="CC Email Addresses" outlined
                          hint="Please separate email addresses with a comma (,)" v-model="stock.ccAddresses">
                        </v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field label="Reply Addresses" outlined
                          hint="Please separate email addresses with a comma (,)" v-model="stock.replyAddresses">
                        </v-text-field>
                      </v-row>
                      <v-row>
                        <v-text class="hint-text" style="color: red; font-size: 16px">
                          {{ replyAdrr(stock) }}
                        </v-text>
                      </v-row>

                      <!-- <v-card-text v-for="(container, indexes) in stock.containers" :key="indexes"> -->
                      <!-- <v-container class="details-container"> -->
                      <div v-for="(container, indexes) in stock.containers" :key="indexes">
                        <v-row :class="getTextClass(container.totalPallets)">
                          <v-col cols="12">
                            <strong style="text-decoration: underline;">
                              {{ container.ctoNo }}
                            </strong>
                          </v-col>
                        </v-row>
                        <v-row :class="getTextClass(container.totalPallets)">
                          <v-col cols="12">
                            Vessel: {{ container.vessel }}
                          </v-col>
                        </v-row>
                        <v-row :class="getTextClass(container.totalPallets)">
                          <v-col cols="12">
                            POL: {{ container.pol }}
                          </v-col>
                        </v-row>
                        <v-row :class="getTextClass(container.totalPallets)">
                          <v-col cols="12">
                            Final Dest: {{ container.finalDest }}
                          </v-col>
                        </v-row>
                        <v-row :class="getTextClass(container.totalPallets)">
                          <v-col cols="12">
                            Receiver: {{ container.recieverName }}
                          </v-col>
                        </v-row>
                        <v-row :class="getTextClass(container.totalPallets)">
                          <v-col cols="12">
                            Temperature: {{ container.temp }}
                          </v-col>
                        </v-row>
                        <v-row :class="getTextClass(container.totalPallets)">
                          <v-col cols="12">
                            Customer Ref: {{ container.customerRef }}
                          </v-col>
                        </v-row>
                        <v-row :class="getTextClass(container.totalPallets)">
                          <v-col cols="12">
                            No Pallets: {{ container.totalPallets }}
                          </v-col>
                        </v-row>
                        <v-row :class="getTextClass(container.totalPallets)">
                          <v-col cols="12">
                            Gross Weight: {{ container.totalGrossWeight }} kg
                          </v-col>
                        </v-row>
                      </div>
                      <!-- </v-container> -->
                      <!-- </v-card-text> -->
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="qxDispatchModal" width="70vw" persistent>
      <v-card>
        <!-- {{qxDispatchData}} -->
        <v-card-title>
          Dispatch Data
          <v-spacer></v-spacer>
          <v-btn icon @click="createQXDispatch()" color='secondary'><v-icon>send</v-icon></v-btn>
          <v-btn text @click="qxDispatchModal = false, qxDispatchData=[],selectedBookings=[]">X</v-btn>
        </v-card-title>
        <v-card-text>
          <template>
              <v-expansion-panels>
                <v-expansion-panel v-for="(item, index) in qxDispatchData" :key="index" :disabled="item.passed">
                  <v-expansion-panel-header style="background-color: var(--v-background-base) !important">
                    <div class="header-content">
                      <v-chip class="mr-2" :color="item.passed ? 'green' : 'red'" > 
                        <v-icon left>
                          {{ item.passed ? 'check_circle' : 'highlight_off' }}
                        </v-icon>
                        {{item.ctoNo}}</v-chip>
                      <v-chip class="mr-2">SSCC Count: {{item.uniqueSSCCCount}}</v-chip>
                      <v-chip class="mr-2">Pallet Quantity: {{item.palletQuantitySum}}</v-chip>
                      <v-chip class="mr-2">GW: {{item.totalPalletGrossWeight}}</v-chip>
                      <v-chip class="mr-2">NW: {{item.totalPalletNettWeight}}</v-chip>
                      <!-- <span class='mx-2'>{{ item.ctoNo + ' (GW: '+ item.totalPalletGrossWeight + ' NW: ' + item.totalPalletNettWeight + ")"  }}</span> -->
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="!item.passed" style="background-color: var(--v-background-base) !important">
                    <v-row>
                      <v-col cols="3">
                        <strong>SSCC</strong>
                      </v-col>
                      <v-col cols="3">
                        <strong>Sequence</strong>
                      </v-col>
                      <v-col cols="3">
                        <strong>Value</strong>
                      </v-col>
                      <v-col cols="3">
                        <strong>StockFile</strong>
                      </v-col>

                    </v-row>
                    <v-divider></v-divider>
                    <v-row v-for="(data, sscc) in item.failedData" :key="sscc">
                      <v-col cols="3">
                        {{ data.sscc }}
                      </v-col>
                      <v-col cols="3">
                        {{ data.sequenceNumber }}
                      </v-col>
                      <v-col cols="3">
                        {{ data.palletQauntity }}
                      </v-col>
                      <v-col cols="3">
                        {{ data.stockFiles }}
                      </v-col>

                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="eq67CommentModal" width="1000px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card :loading="generatingEQ67">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Q67</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="(eq67CommentModal = false), (eq67Comment = null)"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="3">
              <v-switch v-model="eq67Request.submit" label="Submit to Titan" dense></v-switch>
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete outlined dense label="Voyage" v-model="eq67Request.voyage" clearable hide-details
                :items="ppecbVoyages" item-text="vesselName" item-value="voyageNumber">
                <template v-slot:selection="data">
                  <span style="font-size: 12px">{{ data.item.vesselName }} ({{ data.item.voyageNumber }})</span>
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      data.item.vesselName
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.voyageNumber }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="3">
              <v-text-field :key="nCodeKey" outlined dense hide-details label="PPECB N Code"
                v-model="eq67Request.ppecbNcode"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select outlined dense hide-details label="Charge Party" v-model="eq67Request.ppecbInv"
                :items="availableParties" @change="setNCode" item-text="name" item-value="name"></v-select>
            </v-col>
          </v-row>
          <v-textarea v-model="eq67Comment" outlined placeholder="Addtional Comments (optional)"></v-textarea>
          <v-row justify="center">
            <v-btn @click="startProcessingMergedEQ67()" :disabled="!eq67Request.ppecbNcode || !eq67Request.ppecbInv" small rounded
              color="blue" class="mx-1">Generate Q67</v-btn>
            <!-- <v-btn
              @click="generateQ67Excel()"
              small
              rounded
              color="green"
              class="mx-1"
              >Generate Excel Q67</v-btn
            > -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewShipmentFile" width="90vw" :fullscreen="$vuetify.breakpoint.mobile">
      <ViewFile :key="previewKey" v-if="previewShipmentFile && selectedFile && selectedFile.id"
        :fileId="selectedFile.id" @close="previewShipmentFile = false" />
    </v-dialog>
    <v-dialog v-model="updatedContainersModal" width="90vw" :fullsreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Updated Containers
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="updatedContainersModal = false"><v-icon color="red">close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-data-table :items="updatedContainers" dense hide-default-footer :headers="updatedContainerHeaders"
            style="background-color: var(--v-modal-base) !important">
            <template v-slot:[`item.success`]="{ item }">
              <span v-if="item.success"><v-icon color="green">check</v-icon></span>
              <span v-else><v-icon color="red">clear</v-icon></span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>



    <file-upload class="my-0 py-0" post-action="" :multiple="false" :drop="false"
      accept="text/csv,application/vnd.ms-excel,application/x-vnd.oasis.opendocument.spreadsheet,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      :drop-directory="true" v-model="fileList" ref="uploader">
    </file-upload>

  </div>

</template>
<script>
//   import BookingModal from "./BookingModal.vue";
import dateFormat from "dateformat";
import draggable from "vuedraggable";
import * as moment from "moment";
import { PDFDocument } from "pdf-lib";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import ViewFile from '../SystemComponents/PreviewFileModal.vue'
import * as XLSX from "xlsx";
import FileUpload from "vue-upload-component";

export default {
  components: {
    //   BookingModal,
    draggable,
    VuePdfEmbed,
    ViewFile,
    FileUpload
  },
  data: () => ({
    generatingEQ67:false,
    eq67Comment: null,
    eq67CommentModal:false,
    gettingLoadoutData: false,
    loadoutModal: false,
    loadoutEmailData: [],
    hideLoadoutMail: true,
    previewShipmentFile: false,
    allTeams: [],
    booking: {},
    bookings: {
      total: 0,
      data: [],
    },
    bookingTimeout: null,
    bookingQuery: null,
    bookingStatus: [
      { name: "Draft", icon: "edit_note", color: "#F57C00" },
      { name: "Pending", icon: "pending", color: "#009688" },
      { name: "Requested", icon: "verified", color: "#2196F3" },
      { name: "Confirmed", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Ghost", icon: "help", color: "#607D8B" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" },
      { name: "Rejected", icon: "warning", color: "#F44336" },
      { name: 'success', icon: 'check', color: '#4CAF50' },
      { name: 'error', icon: 'error', color: '#F44336' },
      { name: 'Not Started', icon: 'warning', color: '#F57C00' },
    ],
    bookingTypes: [
      { value: "FREIGHT", icon: "mode_of_travel", color: "blue" },
      { value: "LOGISTICS", icon: "mode_of_travel", color: "blue" },
      { value: "DOCUMENTATION", icon: "article", color: "secondary" },
      { value: "HAULAGE", icon: "local_shipping", color: "blue-grey" },
      { value: "MONITORING", icon: "thermostat", color: "teal" },
    ],
    bulkQ67: null,
    columnDrag: false,
    containerHeaders: [
      {
        value: "status",
        text: "Status",
        align: "center",
      },

      {
        value: "ctoNo",
        text: "CTO No.",
        align: "center",
        show: true,
      },
      {
        value: "shipmentFile.fileNumber",
        text: "File",
        align: "center",
        show: true,
      },
      {
        value: "customerContainerRef",
        text: "Customer Ref.",
        align: "center",
        sortable: false,
      },
      {
        text: "Container No.",
        value: "containerNo",
        align: "center",
        sortable: false,
      },
      {
        value: "sealNo",
        text: "Seal No.",
        align: "center",
        sortable: false,
      },
      {
        value: "containerTypeCode",
        text: "Type",
        align: "center",
        sortable: false,
      },
      {
        value: "verificationMethod",
        text: "Method",
        align: "center",
        sortable: false,
      },
      {
        value: "features",
        text: "Features",
        align: "center",
        width: "150px",
        show: true,
        sortable: false,
      },
      {
        value: 'packingListStatus',
        text: 'Packing List',
        align: 'center',
        show: true,

      },
      {
        value: 'loadoutStatus',
        text: 'Loadout Instr.',
        align: 'center',
        show: true,

      },
      {
        value: "dispatchStatus",
        text: "Dispatch",
        align: "center",
        show: true,
      }
    ],
    milestoneTypes: [
      {
        name: "ETD",
        value: "ETD",
      },
      {
        name: "ETA",
        value: "ETA",
      },
      {
        name: "Loading Date",
        value: "LOADING_DATE",
      },
    ],
    qxDispatchModal: false,
    containerStatus: [
      { name: "Unallocated", icon: "warning", color: "orange darken-2" },
      { name: "Ghost", icon: "category", color: "blue-grey" },
      { name: "Moved", icon: "exit_to_app", color: "blue" },
      { name: "Allocated", icon: "task_alt", color: "success" },
      { name: "Cancelled", icon: "cancel", color: "red" },
    ],
    createBookingModal: false,
    dateMenu: false,
    dateKey: 750,
    downloadingBulkQ67: false,
    filteredResults: [],
    filters: null,
    filteredValues: [],
    finishedProcessingQ67: false,
    finishedProcessingMergedQ67: false,
    fullscreen: false,
    fileList: [],
    updatedContainerHeaders: [
      {
        text: "Status",
        value: "success",
        align: "center",
      },
      {
        text: "Booking Ref",
        value: "bookingRef",
        align: "center"
      },
      {
        text: "CTO No",
        value: "ctoNo",
        align: "center"
      },
      {
        text: "Container No.",
        value: "containerNo",
        align: "center",
      },
      {
        text: "Seal No.",
        value: "sealNo",
        align: "center",
      }

    ],
    transportStatuses: [
      {
        text: 'On Hold',
        value: 'onHold',
        color: 'brown lighten-1'
      },
      {
        text: 'Breakdown',
        value: 'BREAKDOWN',
        color: 'red'
      },
      {
        text: "Unallocated",
        value: "PENDING",
        color: "orange",
      },
      // {
      //     text: 'Booking Confirmed',
      //     value: 'BOOKING_CONFIRMED',
      //     color: 'green lighten-1'
      // },
      // {
      //   text: "Pre-Allocated",
      //   value: "PRE_ALLOCATED",
      //   color: "green darken-2",
      // },
      {
        text: "Allocated",
        value: "ALLOCATED",
        color: "lime darken-3",
      },
      {
        text: 'Pre-Loaded',
        value: 'PRE_LOADED',
        color: 'blue-grey darken-2'
      },
      {
        text: 'Collect Tomorrow',
        value: 'COLLECT_TOMORROW',
        color: 'orange darken-2'
      },
      {
        text: 'Collect Later Today',
        value: 'COLLECT_LATER_TODAY',
        color: 'orange darken-2'
      },
      {
        text: "Instr. Sent",
        value: "INSTRUCTION_SENT",
        color: "blue-grey",
      },
      {
        text: "Empty Picked Up",
        value: "GATE_OUT_EMPTY_PICKUP",
        color: "teal",
      },
      {
        text: "En route LP-1",
        value: "EN_ROUTE_LP1",
        color: "cyan darken-2",
      },
      {
        text: "At LP-1",
        value: "AT_LP1",
        color: "cyan darken-4",
      },
      {
        text: "En route LP-2",
        value: "EN_ROUTE_LP2",
        color: "purple darken-2",
      },
      {
        text: "At LP-2",
        value: "AT_LP2",
        color: "purple darken-4",
      },
      {
        text: "En route Gas",
        value: "EN_ROUTE_GAS",
        color: "green lighten-2",
      },
      {
        text: "At Gassing",
        value: "AT_GASSING",
        color: "green lighten-3",
      },
      {
        text: "En route WB",
        value: "EN_ROUTE_WB",
        color: "blue",
      },
      {
        text: "AT WB",
        value: "AT_WB",
        color: "blue lighten-1",
      },
      {
        text: "En route Depot",
        value: "EN_ROUTE_DEPOT",
        color: "blue darken-4",
      },
      {
        text: "At Depot",
        value: "AT_DEPOT",
        color: "blue darken-5",
      },
      {
        text: 'Overnight Genset',
        value: 'OVERNIGHT_GENSET',
        color: '#363062'
      },
      {
        text: 'En route Plug-in',
        value: 'EN_ROUTE_PLUG_IN',
        color: 'blue lighten-2'
      },
      {
        text: 'Plugged-in',
        value: 'PLUG_IN',
        color: 'blue lighten-3'
      },
      {
        text: "En route Port",
        value: "EN_ROUTE_PORT",
        color: "blue darken-1",
      },
      {
        text: "At Port",
        value: "AT_PORT",
        color: "blue darken-2",
      },
      {
        text: "Gate In",
        value: "FULL_DROP_OFF",
        color: "#183A1D",
      },
      {
        text: 'Stacked',
        value: 'STACKED',
        color: 'success'
      },
      {
        text: 'Preadvise Only',
        value: 'preadviseOnly',
        color: 'pink darken-2'
      },
      {
        text: 'Arrange Plugged-In',
        value: 'preadviseOnly',
        color: '#F4CE14'
      },
      {
        text: 'Cancelled',
        value: 'cancelled',
        color: 'red darken-2'
      },
      {
        text: undefined,
        value: undefined,
      },
      {
        text: null,
        value: null,
      }
    ],
    headers: [
      {
        text: "Action",
        align: "center",
        disableSort: true,
        disableFilter: true,
        value: "actions",
        width: "100px",
      },
      {
        text: "Booking Status",
        value: "state",
        align: "center",
        width: "150px",
        filterable: true,
      },
      {
        text: 'Container Status',
        value: 'status',
        align: "center",
      },
      {
        text: 'Transport Status',
        value: 'transportStatus',
        align: "center",
        width: "150px",
        filterable: true,
      },
      {
        text: 'Stock Status',
        value: 'stockStatus',
        align: "center",
        width: "150px",
        filterable: true,
      },
      {
        text: "User",
        value: "userFullName",
        align: "center",
        width: "100px",
        filterable: true,
      },
      {
        text: "Order No",
        value: "orderNo",
        align: "center",
        filterable: true,
      },
      {
        text: 'CTO No',
        value: 'ctoNo',
        align: "center",
        filterable: true,
      },
      {
        text: "Container No.",
        value: "containerNo",
        align: "center",
        filterable: true,
      },
      {
        text: "Vessel",
        value: "vesselVoyage",
        align: "center",
        width: "200px",
      },
      {
        text: "Shipper",
        value: "shipperName",
        width: "220px",
        filterable: true,
      },
      {
        text: "Consignee",
        value: "consigneeName",
        width: "220px",
        filterable: true,
      },
      {
        text: 'Customer',
        value: 'customerName',
        width: "220px",
        filterable: true,
      },
      {
        text: "Carrier",
        value: "shippingLineName",
        align: "left",
      },
      {
        text: 'Transp Co.',
        value: 'transportCoordinator',
        align: "center",
      },
      {
        text: 'Depot',
        value: 'depot',
        align: "center",
      },
      {
        text: 'LP-1',
        value: 'lp1',
        align: "center",
      },
      {
        text: 'LP-2',
        value: 'lp2',
        align: "center",
      },
      {
        text: 'Plug-in-depot',
        value: 'PluggedInDepot',
        align: "center",
      },
      {
        text: 'Features',
        value: 'features',
        align: "center",
        width: "220px",

      },
      {
        text: 'Loading date',
        value: 'loadingDate',
        align: "center",
      },
      {
        text: 'Carrier Ref',
        value: 'carrierReferenceNumber',
        align: "center",
      },
      {
        text: "POL",
        value: "portOfLoadValue",
        align: "center",
        width: "100px",
      },
      {
        text: "ETD",
        value: "etd",
        align: "center",
        width: "100px",
      },
      {
        text: "Dest.",
        value: "finalDestinationValue",
        align: "center",
        width: "100px",
      },
      {
        value: 'packingListStatus',
        text: 'Packing List',
        align: 'center',
        show: true,

      },
      {
        value: 'loadoutStatus',
        text: 'Loadout Instr.',
        align: 'center',
        show: true,

      },
      {
        value: "dispatchStatus",
        text: "Dispatch",
        align: "center",
        show: true,
      },
      // {
      //   value:"q67Status",
      //   text: "Q67 Status",
      //   align: "center",
      //   show:true
      // }
    ],
    quickSelectDates: [
      {
        text: 'Today',
        type: 'current',
        value: 'today',
        difference: 0
      },
      {
        text: 'Current 3 Days',
        type: 'current',
        value: 'current_3day',
        difference: 0
      },
      {
        text: "This week",
        type: "current",
        value: "current_week",
        difference: 0,
      },
      {
        text: "Next 2 weeks",
        type: "future",
        value: "2_week",
        difference: 0,
      },
    ],
    imageLoading: false,
    importExport: 0,
    item: {},
    loading: false,
    loadingClone: false,
    loadingContainers: false,
    loadingOrganisations: false,
    loadingSaveCustom: false,
    loadingTeams: false,
    myTeams: [],
    orderItemStatus: [
      { name: "In Progress", icon: "pending", color: "blue" },
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "Confirmed", icon: "verified", color: "teal" },
      { name: "Instruction Sent", icon: "email", color: "indigo" },
      { name: "Instruction Cancelled", icon: "warning", color: "orange" },
      { name: "Stuffed", icon: "check_circle_outline", color: "lime" },
      { name: "Stuff Discrepancy", icon: "error", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "red" },
    ],
    organisations: {
      total: 0,
      data: [],
    },
    creatingDispatch: false,
    params: null,
    previewModal: false,
    processingQ67: false,
    processingMergedQ67: false,
    q67CommentModal: false,
    q67Comment: null,
    q67Request: {
      ppecbInv: "forwarder",
    },
    q67Modal: false,
    q67UpdateKey: 450,
    refreshBooking: 1050,
    search: null,
    searchHeaders: {},
    searchKey: 100,
    selectAllBookings: false,
    selectedBookings: [],
    selectedBooking: {},
    selectedBookingModal: false,
    selectedDate: null,
    selectedHeaders: [],
    selectedBookingItem: {},
    settingsChange: false,
    selectedTeams: [],
    selectedWeek: null,
    showMenu: false,
    sortBy: null,
    sortDesc: false,
    updateFilterValue: 350,
    userLayout: null,
    x: null,
    y: null,
    updatedContainers: [],
    updatedContainersModal: false,
    mailingLoadoutInstr: false,
    qxDispatchData: [],
    disalbedDispatch:true,
    integrationForms: [],
    eq67Request:{},
    q67Disabled: true,
    availableParties:[],
    nCodeKey:102,
    loadingVoyages:false,
    ppecbVoyages:[],
  }),
  watch: {

    "emailData.stockData": {
      handler(val) {
        console.log('change in stockData')
        if (val) {
          this.replyAdrr(val)
        }
      },
      deep: true
    },
    fileList: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.submitFiles();
        }
      },
    },
    selectAllBookings(val) {
      if (val) {
        this.selectedBookings = this.displayData;

      } else {
        this.selectedBookings = [];
      }
    },
    showMenu(val) {
      if (!val) {
        this.selectedBookingItem = {};
      }
    },
    "selectedBookings.length": {
      immediate: true,
      handler(val) {
        if (val > 0) {
          // this.hideLoadout
          this.disableDispatch()
          this.disableQ67()
          this.loadoutMailHide()
          this.disableQ67()
        }
      },
    },
  },
  beforeCreate() {
    if (localStorage.bookingContainerParams) {
      this.params = JSON.parse(localStorage.bookingContainerParams);
      if (!this.params.dateType) {
        this.params.dateType = "ETD";
      }
    }

  },
  created() {
    if (localStorage.bookingContainerParams) {
      this.params = JSON.parse(localStorage.bookingContainerParams);
      if (!this.params.dateType) {
        this.params.dateType = "ETD";
      }
    }
    if (localStorage["bookingContainerFilters_" + this.$store.state.currentOrg.id]) {
      this.filters = JSON.parse(
        localStorage["bookingContainerFilters_" + this.$store.state.currentOrg.id]
      );
      // console.log("existing filters", this.filters)
      if (typeof this.filters != "object") {
        // this.filters = {}
        this.resetFilters();
      }
      let result = [];
      let keys = Object.keys(this.filters);
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
    }
    if (!this.params) {
      this.params = {
        dateType: "ETD",
        selectedDate: "current_week",
        dates: [],
      };
    }
    if (!this.filters) {
      this.filters = {};
      this.resetFilters();
    }
  },
  async mounted() {
    await this.getTeams();
    this.load();
    this.getUserLayout();
    // this.resetFilters();
  },
  computed: {
    disabledLoadoutMail() {
      if (this.loadoutEmailData && this.loadoutEmailData.containers) {
        console.log('loadoutEmailData', this.loadoutEmailData);
        return this.loadoutEmailData.every(stock =>

          stock.emailAdresses && stock.emailAdresses.trim() !== '' && stock.containers.every(container => container.totalPallets > 0)
        ) === false;

      }
      return true
    },
    calculatedHeight() {
      let height = window.innerHeight;
      return height * 0.63 + "px";
    },

    countContainers() {

      const searchActive = this.search && this.search.length > 0;

      const dataToUse = searchActive ? this.displayData : this.filteredResults;

      return dataToUse.map((x) => x.numContainers).reduce((a, b) => a + b, 0);
    },
    dateText() {
      if (this.params.dates.length === 0) {
        return "Select Dates";
      } else if (this.params.dates.length === 1) {
        return "Week: " + moment(this.params.dates[0]).isoWeek();
      } else {
        return `${this.params.dates[0]} - ${this.params.dates[1]}`;
      }
    },
    displayData() {
      let result = this.filteredResults;
      if (this.search) {
        result = result.filter(
          (x) =>
            (x.lp1 &&
              x.lp1.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.lp2 &&
              x.lp2.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.PluggedInDepot &&
              x.PluggedInDepot.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.containerNo &&
              x.containerNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.ctoNo &&
              x.ctoNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.orderNo &&
              x.orderNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.shipperName &&
              x.shipperName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consigneeName &&
              x.consigneeName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.shippingLineName &&
              x.shippingLineName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.vesselVoyage &&
              x.vesselVoyage
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||

            (x.portOfLoadValue &&
              x.portOfLoadValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||

            (x.finalDestinationValue &&
              x.finalDestinationValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||

            (x.carrierReferenceNumber &&
              x.carrierReferenceNumber
                .toLowerCase()
                .includes(this.search.toLowerCase()))

        );
      }
      if (this.sortBy) {
        result = result.sort((a, b) => {
          if (this.sortDesc) {
            return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
          } else {
            return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
          }
        });
      }
      return result;
    },
    tableWidth() {
      let result = 0;
      this.selectedHeaders.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 150);
      });
      return result + "px";
    },
  },
  methods: {
    async getPPECBVoyages(booking) {
      this.loadingVoyages = true;
      this.ppecbVoyages = await this.$API.getPPECBVoyages();
      if (booking.vesselName) {
        let findVessel = this.ppecbVoyages.find(
          (x) =>
            x.vesselName.toLowerCase() == booking.vesselName.toLowerCase()
        );
        if (findVessel) {
          this.q67Request.voyage = findVessel.voyageNumber;
        }
      }
      this.loadingVoyages = false;
    },
    setNCode() {
      let find = this.availableParties.find(
        (x) => x.name == this.eq67Request.ppecbInv
      );
      if (find && find.nNumber) {
        this.eq67Request.ppecbNcode = find.nNumber;
        this.nCodeKey++;
      }
    },
    bookingParties(booking) {
      let parties = [
        "forwarder",
        "shipper",
        "onBehalfShipper",
        "onBehalfForwarder",
      ];
      let result = [];
      for (let party of parties) {
        if (booking[party]) {
          result.push(booking[party]);
        }
      }
      return result;
    },
    async viewEQ67() {
      this.integrationForms = await this.$API.getBookingForms(
          this.selectedBookings[0].bookingId
        );
        this.availableParties = this.bookingParties(this.selectedBookings[0]);
        console.log('this.integrationForms', this.integrationForms)
      let findForm = this.integrationForms.find(
        (x) => x.countryIntegrationId == 9 || x.countryIntegrationId == 11
      );
      console.log('findForm', findForm)
      
      this.eq67Request = {};
      if (findForm && findForm.answers) {
        findForm.answers = JSON.parse(findForm.answers);
        if (findForm.answers["PPECB_N_CODE"]) {
          this.eq67Request.ppecbNcode = findForm.answers["PPECB_N_CODE"];
        }
        if (
          findForm.answers["CHARGE_PARTY"] &&
          this.selectedBookings[0][findForm.answers["CHARGE_PARTY"]]
        ) {
          this.eq67Request.ppecbInv =
             this.selectedBookings[0][findForm.answers["CHARGE_PARTY"]].name;
          if (this.selectedBookings[0][findForm.answers["CHARGE_PARTY"]].nNumber) {
            this.eq67Request.ppecbNcode =
               this.selectedBookings[0][findForm.answers["CHARGE_PARTY"]].nNumber;
          }
        }
        if (findForm.answers["COMMENT"]) {
          this.eq67Request.comments = findForm.answers["COMMENT"];
        }
      }
      if (!this.eq67Request.ppecbInv) {
        let findOrg = this.availableParties.find(
          (x) => x.id == this.$store.state.currentOrg.id
        );
        if (findOrg) {
          this.eq67Request.ppecbInv = findOrg.name;
        }
      }
      this.eq67Request.submit = false

      this.setNCode();
      this.getPPECBVoyages(this.selectedBookings[0]);
      this.eq67CommentModal = true;
    },
    disableQ67(){
      if(this.selectedBookings.length === 0){
        this.q67Disabled = true
      }
      else{
        let vesselName = this.selectedBookings[0].booking.mainCarriageVesselName
        let voyage = this.selectedBookings[0].booking.mainCarriageConveyanceNumber
        let contractNumber = this.selectedBookings[0].booking.contractNumber
        let shipper = this.selectedBookings[0].shipper.id
        let shippingLine = this.selectedBookings[0].shippingLine.id
        let portOfLoadValue = this.selectedBookings[0].portOfLoadValue
        let check = this.selectedBookings.every(x => x.booking.mainCarriageVesselName === vesselName && x.booking.mainCarriageConveyanceNumber === voyage && x.booking.contractNumber === contractNumber && x.shipper.id === shipper && x.shippingLine.id === shippingLine && x.portOfLoadValue === portOfLoadValue)
        this.q67Disabled = !check
      }
    },
    disableDispatch() {
      if (this.selectedBookings.length === 0) {
        this.disalbedDispatch = true
      }
      else {
        let disableStatus = this.selectedBookings.every(container => container.shipmentFileId && container.shipmetFileUCRNo)
        this.disalbedDispatch = !disableStatus
        // return this.disableDispatch        
      }
    },
    async validateDispatchPalletQauntity() {
      let selectedContainers = this.selectedBookings.map(x => x.id);
      // console.log('selectedContainers', selectedContainers)
      let result = await this.$API.validateDispatchPalletQauntity({
        containerIds: selectedContainers
      });
      console.log('result', result)
      // let resultCheck = result.every(x => x.passed)
      // if (resultCheck) {
      //   this.qxDispatchData = result

      //   this.createQXDispatch();
      // }
      // else {
        this.qxDispatchData = result
        this.qxDispatchModal = true
      // }
    },
    async createQXDispatch() {
      this.creatingDispatch = true;
      let dispatchRequest = {};
      if(this.qxDispatchData){
        
        let passedContainers = this.qxDispatchData.filter(x => x.passed)
        dispatchRequest.containers = passedContainers.map((x) => x.bookingContainerId);
        this.qxDispatchData= []
      } else {
        dispatchRequest.containers = this.selectedBookings.map((x) => x.id);
      }


      if(dispatchRequest.containers.length > 0){
        let res = await this.$API.createQXDispatches(dispatchRequest);
        if (res.status === 201) {
        this.$message.success(res.message);
      } else if (res.status === 420) {
        this.$message.warning(res.message);
      } else if (res.status === 418) {
        this.$message.error(res.message);
      } else {
        this.$message.error("Error when creating Dispatches to QX.");
      }
      this.$message.success("Dispatches created successfully");
      }
      else{
        this.$message.error("No containers is valid for sending to QX");
      }
     
      this.selectedBookings = [];
      this.creatingDispatch = false;
      this.qxDispatchModal = false;

    },
    async emailLoadout() {
      this.mailingLoadoutInstr = true
      console.log('this.loadoutEmailData',this.loadoutEmailData)
      let result = await this.$API.emailBulkLoadOutInstructions(this.loadoutEmailData)
      console.log('result', result)
      this.selectedBookings = []
      this.mailingLoadoutInstr = false
      this.loadoutModal = false
    },
    replyAdrr(stock) {
      if (stock.replyAddresses && stock.ccAddresses) {
        return "** Reply addresses will be used for replying to messages.";
      } else if (stock.replyAddresses && !stock.ccAddresses) {
        return "** Reply addresses will be used for replying to messages.";
      } else if (!stock.replyAddresses && stock.ccAddresses) {
        return "** CC addresses will be used for reply addresses.";
      } else if (!stock.replyAddresses && !stock.ccAddresses) {
        return "** No reply addresses will be added if CC or Reply fields are empty.";
      }
      return "** Default case or no data available.";
    },
    getTextClass(pallets) {
      return pallets === 0 ? 'red-text' : '';
    },
    async getLoadOutMailData() {
      let data = []
      this.gettingLoadoutData = true
      let containerMilestones = this.selectedBookings.map((x) => x.containerMilestones)
      let result = []
      containerMilestones.forEach((x) => {
        let find = x.filter((y) => y.type == "LOADING_POINT_1" || y.type == "LOADING_POINT_2")
        result.push(...find)
      })

      let uniquePOI = [...new Set(result.map(x => x.poiId))]

      let tempPoiData = []

      for (let i = 0; i < uniquePOI.length; i++) {
        let containerIds = this.selectedBookings.filter((x) => x.containerMilestones.some((y) => y.poiId === uniquePOI[i])).map((x) => x.id);
        let poiData = {
          poiId: uniquePOI[i],
          containerIds: containerIds
        }
        tempPoiData.push(poiData)
      }
      let stockData = await this.$API.getStockLoadoutDetails(tempPoiData)
      let dates = this.formatDate(new Date())
      let uniqueBookings = [...new Set(this.selectedBookings.map(x => x.bookingId))]
      console.log('uniqueBookings', uniqueBookings);
      for (let x = 0; x < uniqueBookings.length; x++) {
        console.log('uniqueBookings[x]', uniqueBookings[x])
        //get stockData where bookingId = uniqueBookings[x]
        console.log('stockData', stockData);
        let findStockData = stockData.filter(y => y.bookingId === uniqueBookings[x])
        console.log('findStockData', findStockData)
        let bookingData = this.selectedBookings.filter(y => y.bookingId === uniqueBookings[x])[0]
        let emailData = {
          vessel: bookingData.booking.mainCarriageVesselName,
          pol: bookingData.booking.portOfLoadValue,
          finalDest: bookingData.finalDestinationValue,
          temp: bookingData.setPointTemp + ' ' + '(' + bookingData.regimeCode + ')',
          stockData: findStockData,
          voyage: bookingData.booking.mainCarriageConveyanceNumber,
          bookingId: bookingData.bookingId,
          pod: bookingData.booking.portOfDischargeValue,
        }
        let title = 'LOAD OUT' + ' - ' + bookingData.booking.mainCarriageVesselName + ' & ' + bookingData.booking.mainCarriageConveyanceNumber + ' - ' + bookingData.booking.shipper.name + ' - ' + dates.isoFormat + ' ' + dates.time
        if (emailData.stockData.length > 0) {
          emailData.stockData.forEach(y => {
            y.title = title
          })
        }
        data.push(emailData)
      }

      let poiData = []
      for (let j = 0; j < data.length; j++) {
        console.log('data', data[j])
        let currentBookingData = data[j].stockData
        currentBookingData.forEach(y => {
          let find = poiData.find(z => z.poiId === y.poiId)
          if (find) {
            //add container to containers array
            let obj = {
              id: y.containers.id,
              totalPallets: y.containers.totalPallets,
              totalGrossWeight: y.containers.totalGrossWeight,
              ctoNo: y.containers.ctoNo,
              customerRef: y.containers.customerRef,
              recieverName: y.containers.recieverName,
              loadingDate: y.containers.loadingDate,
              vessel: data[j].vessel,
              pol: data[j].pol,
              finalDest: data[j].finalDest,
              temp: data[j].temp,
              title: data[j].title,
              bookingId: data[j].bookingId
            }
            find.containers.push(obj)
          }
          else {
            let obj = {
              poiId: y.poiId,
              poiName: y.poiName,
              containers: y.containers,
              title: data[j].title,
            }
            obj.containers.forEach(z => {
              z.vessel = data[j].vessel,
                z.pol = data[j].pol,
                z.finalDest = data[j].finalDest,
                z.temp = data[j].temp,
                z.title = data[j].title,
                z.bookingId = data[j].bookingId
            })
            poiData.push(obj)
          }

        })
      }
      this.loadoutEmailData = poiData
      this.gettingLoadoutData = false
      this.hideLoadoutMail = true
      this.loadoutModal = true

    },
    loadoutMailHide() {
      if (this.selectedBookings.length > 0) {
        let result = this.selectedBookings.every(x =>
          x.shipper.id === 3892
        )
        this.hideLoadoutMail = !result
      }
      else {
        this.hideLoadoutMail = true
      }
    },
    viewDocument(item) {
      this.item = item;
      console.log('this.item', this.item);
      if (
        this.item.type &&
        (this.item.type.includes("image/") ||
          this.item.type.includes("application/pdf"))
      ) {
        this.imageLoading = true;
      }
      this.previewModal = true;
    },
    async startProcessingMergedEQ67() {
      this.processingMergedQ67 = true;

      let result = await this.$API.generateMergedQ67({
        bookingContainers: this.selectedBookings.map((x) => x.id),
        comment: this.q67Comment,
        ...this.eq67Request,
      });
      this.bulkQ67 = result;
      this.downloadDocument(this.bulkQ67.q67);
      this.q67UpdateKey++;
      this.finishedProcessingMergedQ67 = true;
      this.processingMergedQ67 = false;
    },
    async startProcessingMergedQ67() {
      this.processingMergedQ67 = true;

      let result = await this.$API.generateMergedQ67({
        bookingContainers: this.selectedBookings.map((x) => x.id),
        comment: this.q67Comment,
        ...this.q67Request,
      });
      this.bulkQ67 = result;
      this.downloadDocument(this.bulkQ67.q67);
      this.q67UpdateKey++;
      this.finishedProcessingMergedQ67 = true;
      this.processingMergedQ67 = false;
    },
    async startProcessingQ67() {
      this.processingQ67 = true;
      for (let i = 0; i < this.selectedBookings.length; i++) {
        let result = await this.$API.generateQ67({
          bookingContainer: this.selectedBookings[i].id,
          comment: this.q67Comment,
          ...this.q67Request,
        });
        if (result && result.q67) {
          this.selectedBookings[i].q67 = result.q67;
          this.selectedBookings[i].q67Id = result.q67Id;
          this.selectedBookings[i].q67Excel = result.q67Excel;
          this.selectedBookings[i].q67ExcelId = result.q67ExcelId;
        } else if (result && result.message) {
          this.selectedBookings[i].errorMessage = result.message;
        }

        this.q67UpdateKey++;
      }
      this.finishedProcessingQ67 = true;
      this.processingQ67 = false;
    },
    async submitFiles() {
      let pending = this.fileList.filter((x) => !x.loading);
      console.log('pending', pending)
      for (let i = 0; i < pending.length; i++) {
        var vm = this;
        let file = pending[i].file;
        if (window.FileReader) {
          var reader = new FileReader();
          if (pending[i].file.type == "text/csv") {
            console.log('Not handling this type file')

          } else {
            reader.onload = (e) => {
              /* Parse data */
              console.log('inside reader', e)
              const bstr = e.target.result;
              const wb = XLSX.read(bstr, { type: "binary" });
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
              vm.excelJson(data, file);
            };

            reader.readAsBinaryString(file);
          }

          reader.onerror = function (evt) {
            if (evt.target.error.name == "NotReadableError") {
              alert("Cannot read file !");
            }
          };
        } else {
          alert("FileReader are not supported in this browser.");
        }
      }
    },
    async excelJson(excel) {
      this.bulkUpdateData = [];
      excel = excel.filter((x, index) => x.length > 0 && index != 0);
      excel = excel.map((x) => ({
        carrierReferenceNumber: x[0] ?? null,
        ctoNo: x[1] ?? null,
        containerNo: x[2] ?? null,
        sealNo: x[3] ?? null,
      }));
      this.updatedContainers = await this.$API.updateCarrierHaulContainers(excel);
      console.log('updatedContainers', this.updatedContainers)
      //show in new dialog 
      if (this.updatedContainers) {
        this.updatedContainersModal = true
      }

    },
    orderItemStatusColor(status) {
      if (status) {
        let color = this.orderItemStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return color ? color.color : "grey";
      } else return "grey";
    },
    uploadContainerDetails() {
      this.$refs.uploader.$el.children[0].click();
    },
    viewShipmentFile(file) {
      this.selectedFile = file
      this.previewKey++
      this.previewShipmentFile = true
    },
    popupFileStauts(file) {
      let arr = [];

      for (let x of file) {
        let obj = {};
        if (x.status == "Processed" || x.status == "Ready") {
          obj.text = x.total + " - " + x.fileNumber;
          obj.color = "#99B080";
        } else if (x.status == "Draft") {
          obj.text = x.total + " - " + x.fileNumber;
          obj.color = "orange";
        } else {
          obj.text = x.total + " - " + x.status;
          obj.color = "#FF6969";
        }
        arr.push(obj);
      }

      return arr;
    },
    checkFileStatus(file) {
      if (file.length > 0) {
        const allProcessedOrReady = file.every(
          (item) => item.status === "Ready" || item.status === "Processed"
        );
        return allProcessedOrReady ? "READY" : "OUTSTANDING";
      } else {
        return "No Containers";
      }
    },

    addToFilter(value, field) {
      if (this.filters[field].includes(value)) {
        this.filters[field] = this.filters[field].filter((x) => x != value);
      } else {
        this.filters[field].push(value);
      }
      this.updateFilterValue++;
      localStorage.setItem(
        "bookingContainerFilters_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.filters)
      );
      let keys = Object.keys(this.filters);
      let result = [];
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
      this.selectAllBookings = false;
      this.selectedBookings = [];
      this.calculateFilteredResults();
    },

    changeDateType(name) {
      console.log('name coming in', name)
      if (name == "ETD") {
        this.params.dateType = "ETD";
      } else if (name == "ETA") {
        this.params.dateType = "ETA";
      } else if (name == "LOADING_DATE") {
        this.params.dateType = 'LOADING DATE'
      }
      this.dateKey++;
      this.getBookings();
    },
    changeImportExportFilter(movementType) {
      if (movementType == "ALL") {
        this.importExport = 0;
        this.filters.movementType = [];
      } else {
        this.filters.movementType = [movementType];
        this.importExport = movementType == "EXPORT" ? 1 : 2;
      }

      this.calculateFilteredResults();
      this.updateFilterValue++;
    },

    bookingTypeColor(type) {
      let find = this.bookingTypes.find((x) => x.value == type);
      return find ? find.color : null;
    },
    bookingTypeIcon(type) {
      let find = this.bookingTypes.find((x) => x.value == type);
      return find ? find.icon : null;
    },
    calculateFilteredResults() {
      let filterKeys = Object.keys(this.filters);
      let filterResult = [];
      filterKeys.forEach((x) => {
        filterResult = [
          ...filterResult,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = filterResult;
      let result = [...this.bookings.data];
      let keys = Object.keys(this.filters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.filters[key] && this.filters[key].length > 0) {
          result = result.filter((x) => this.filters[key].includes(x[key]));
        }
      }
      this.filteredResults = result;
    },
    changeSortBy(value) {
      if (this.sortBy == value) {
        if (this.sortDesc) {
          this.sortBy = null;
          this.sortDesc = false;
        } else {
          this.sortDesc = true;
        }
      } else {
        this.sortBy = value;
        this.sortDesc = false;
      }
      this.selectAllBookings = false;
      this.selectedBookings = [];
    },
    containerStatusColor(item) {
      if (item.isDeleted) {
        return "red";
      } else if (item) {
        let find = this.containerStatus.find(
          (x) => x.name.toLowerCase() === item.toLowerCase()
        );
        if (find) {
          return find.color;
        } else {
          return "orange darken-2";
        }
      } else {
        return "orange darken-2";
      }
    },
    transportStatusColor(status) {
      return this.transportStatuses.find(x => x.text == status)?.color
    },
    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
      }
    },
    async getBookings() {
      if (this.bookingTimeout) {
        clearTimeout(this.bookingTimeout);
      }
      if (this.loading && this.bookingQuery) {
        this.bookingQuery.abort();
      }
      this.loading = true;
      let params = { ...this.params };
      params.teams = this.selectedTeams;
      if (this.params.dates.length == 1) {
        params.dates = [
          moment(this.params.dates[0])
            .startOf("isoWeek")
            .toISOString()
            .substring(0, 10),
          moment(this.params.dates[0])
            .endOf("isoWeek")
            .toISOString()
            .substring(0, 10),
        ];
      }
      this.selectedBookings = [];
      this.bookingTimeout = setTimeout(async () => {
        this.bookingQuery = new AbortController();
        const signal = this.bookingQuery.signal;
        this.bookings = await this.$API.getBookingContainerOverviews({
          params: params,
          signal,
        });
        // console.log('this.bookings', this.bookings)
        this.calculateFilteredResults();
        this.loading = false;
      }, 500);
    },

    async getRelations() {
      this.loadingOrganisations = true;
      this.organisations = await this.$API.getRelationBasic({
        // params: this.params,
      });
      this.loadingOrganisations = false;
    },
    async getUserLayout() {
      this.selectedHeaders = this.headers;
      this.userLayout = await this.$API.getUserTableLayout({
        params: {
          module: "Bookings_Containers",
        },
      });
      if (this.userLayout && this.userLayout.config) {
        this.selectedHeaders = this.userLayout.config.filter((x) =>
          this.headers.map((y) => y.value).includes(x.value)
        );
      } else {
        this.selectedHeaders = this.headers;
      }
    },
    async getTeams() {
      this.loadingTeams = true;
      this.allTeams = await this.$API.getTeams();
      this.myTeams = await this.$API.getUserTeams();
      if (localStorage["bookingTeams_" + this.$store.state.currentOrg.id]) {
        this.selectedTeams = JSON.parse(
          localStorage["bookingTeams_" + this.$store.state.currentOrg.id]
        );
      } else {
        this.selectedTeams = this.myTeams.map((x) => x.teamId);
      }
      this.loadingTeams = false;
    },
    headerOptions(value) {
      let result = [
        ...new Set(
          this.bookings.data
            // .filter(x => x[value])
            .map((x) => ({ name: x[value] ?? "(Blank)", value: x[value] }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      if (this.searchHeaders[value]) {
        uniqueValues = uniqueValues.filter(
          (x) =>
            x &&
            x.toLowerCase().includes(this.searchHeaders[value].toLowerCase())
        );
      }
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      // if (value == 'status') {
      //     result.push({ name: 'Unallocated', value: null })
      // }
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      return result;
    },
    async load() {
      this.getBookings();
      await this.getRelations();
    },
    resetFilters() {
      this.filters = {};
      let keys = this.headers.map((x) => x.value);
      keys.forEach((x) => {
        this.searchHeaders[x] = null;
        this.filters[x] = [];
      });
      this.filteredValues = [];
      this.selectedBookings = [];
      this.selectAllBookings = false;
      this.updateFilterValue++;
      localStorage.removeItem(
        "bookingContainerFilters_" + this.$store.state.currentOrg.id
      );
      this.calculateFilteredResults();
    },
    async saveCustomHeaders() {
      this.loadingSaveCustom = true;
      if (this.userLayout && this.userLayout.id) {
        let result = await this.$API.updateUserTableLayout({
          id: this.userLayout.id,
          config: this.selectedHeaders,
        });
        this.settingsChange = false;
        this.userLayout = result;
        this.userLayout.config = this.selectedHeaders;
        this.$message.success("Successfully updated!");
      } else {
        let result = await this.$API.createUserTableLayout({
          module: "Bookings_Containers",
          config: this.selectedHeaders,
        });
        this.settingsChange = false;
        this.userLayout = result;
        this.userLayout.config = this.selectedHeaders;
        this.$message.success("Successfully saved setup!");
      }
      this.loadingSaveCustom = false;
    },
    selectDate(value) {
      this.params.selectedDate = value;
      this.setDateRange(value);
      // console.log("Setting params", JSON.stringify(this.params));
      localStorage.setItem("bookingContainerParams", JSON.stringify(this.params));
    },
    setDateFilter() {
      if (this.params.dates.length == 2) {
        if (this.params.dates[1] < this.params.dates[0]) {
          let dates = [this.params.dates[1], this.params.dates[0]];
          this.params.dates = dates;
        }
      }
      localStorage.setItem("bookingContainerParams", JSON.stringify(this.params));
      this.getBookings();
      this.dateMenu = false;
    },
    setDateRange(val) {
      switch (val) {
        case "current_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 30))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_week":
          this.params.dates = [
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
            )
              .toISOString()
              .substring(0, 10),
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
            )
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_month":
          this.params.dates = [
            new Date(new Date().setDate(1)).toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "past_day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 3))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 7))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "today":
          this.params.dates = [
            new Date().toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "tomorrow":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 7))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "2_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 14))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "6_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 42))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
      }
      this.getBookings();
    },
    statusColor(status) {
      let color = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return color ? color.color : "grey";
    },
    statusIcon(status) {
      let find = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return find ? find.icon : "";
    },
    selectBooking(item) {
      let find = this.selectedBookings.find((x) => x.id == item.id);
      if (!find) {
        this.selectedBookings.push(item)
        console.log('this.selectedBookings', this.selectedBookings)
      } else {
        this.selectedBookings = this.selectedBookings.filter(
          (x) => x.id != item.id
        );
      }
    },
    selectAllTeams() {
      this.selectedTeams = this.allTeams.map((x) => x.id);
      localStorage.setItem(
        "bookingTeams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.selectedTeams)
      );
      this.getBookings();
    },
    selectTeam(id) {
      let findIndex = this.selectedTeams.indexOf(id);
      if (findIndex > -1) {
        this.selectedTeams.splice(findIndex, 1);
      } else {
        this.selectedTeams.push(id);
      }
      localStorage.setItem(
        "bookingTeams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.selectedTeams)
      );
      this.getBookings();
    },

    async downloadBookingData() {
      console.log('this.selectedBookings', this.selectedBookings)
      let contianerIds = this.selectedBookings.map((x) => x.id);
      let obj = {
        containerIds: contianerIds,
      };
      let response = await this.$API.getBookingBulkDownload(obj);

      if (response && response.dataUri) {
        const link = document.createElement("a");
        link.href = response.dataUri;

        // Update the 'download' attribute to reflect Excel file extension (.xlsx)
        link.setAttribute("download", `LoadsReport.xlsx`);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("No data received from server");
      }
    },

    toggleColumn(item) {
      if (item.show === true) {
        this.headers.find((header) => header.value === item.value).show = true;
      } else {
        this.headers.find((header) => header.value === item.value).show = false;
      }
      this.selectedHeaders = this.headers.filter((x) => x.show);
    },
    updateSearchKey() {
      this.searchKey++;
    },
    updateUserLayout() {
      this.settingsChange = true;
    },
    viewBooking(item) {
      const url = "/booking/" + item.systemReference;
      window.open(url, '_blank');
    }

  },
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 550px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.green {
  color: #004225;
}

.orange {
  color: orange;
}

.red {
  color: #200e3a;
}

/* th div {
      border: 0;
      min-height: 20px;
      min-width: 20px;
  } */

.red-text {
  color: red;
}

.details-container {
  margin-top: 20px;
  /* Adjust the top margin as needed */
  margin-bottom: 20px;
  /* Adjust the bottom margin as needed */
  margin-left: 20px;
  /* Adjust the left margin as needed */
}
</style>