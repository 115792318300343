<template>
  <div>
    <v-card flat style="background-color: var(--v-background-base) !important">
      <v-toolbar
        :class="!$vuetify.breakpoint.mobile ? 'toolbarHead' : 'toolbarHeadMobile'"
        color="greyBase"
        dark
        :extended="!$vuetify.breakpoint.mobile"
        flat
        extension-height="45"
      >
        <v-toolbar-title v-if="!$vuetify.breakpoint.mobile">
          <b class="mr-3">Bookings</b>
        </v-toolbar-title>
        <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
        <v-btn-toggle
          v-if="!$vuetify.breakpoint.mobile"
          mandatory
          outlined
          :light="!$store.state.darkMode"
          v-model="toggleView"
          rounded
          color="blue"
        >
          <v-btn small outlined>
            <v-icon v-if="!$vuetify.breakpoint.mobile" left small :color="toggleView == 0 ? 'blue' : 'primaryText'"
              >confirmation_number</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)">Bookings</span>
          </v-btn>
          <v-btn small outlined>
            <v-icon v-if="!$vuetify.breakpoint.mobile" left small :color="toggleView == 1 ? 'blue' : 'primaryText'"
              >verified</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)">Booking Requests</span> <v-chip v-if="!$vuetify.breakpoint.mobile" small righ class="ml-1">{{ bookingRequestCount.count }}</v-chip>
          </v-btn>
          <v-btn small outlined>
            <v-icon v-if="!$vuetify.breakpoint.mobile" small left :color="toggleView == 2 ? 'blue' : 'primaryText'"
              >widgets</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)">Containers</span>
          </v-btn>
          <v-btn small outlined>
            <v-icon v-if="!$vuetify.breakpoint.mobile" small left :color="toggleView == 3 ? 'blue' : 'primaryText'"
              >description</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)">Files</span>
          </v-btn>
          <v-btn small outlined>
            <v-icon v-if="!$vuetify.breakpoint.mobile" small left :color="toggleView == 4 ? 'blue' : 'primaryText'"
              >warning</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)">Exceptions</span>
          </v-btn>
        </v-btn-toggle>
        <v-menu bottom offset-y  v-if="$vuetify.breakpoint.mobile">
          <template v-slot:activator="{ on, attrs}">
            <v-btn rounded small v-on="on" v-bind="attrs">
              <v-icon small left color="blue">{{ getView().icon }}</v-icon>
              <span style="text-transform: none; color: var(--v-primaryText-base)">{{ getView().name }}</span>
            </v-btn>
          </template>
          <v-card style="background-color: var(--v-greyBase-base) !important;">
                  <v-list class="pa-0">
                    <v-list-item dense v-for="item in toggles" :key="item.num" :class="item.num == toggleView ? 'selectedToggle' : ''" @click="toggleView = item.num">
                      <v-list-item-icon class="mr-4">
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action v-if="item.num == 1">
                        <v-chip color="white" small right><span style="color: blue; font-size: 14px;"><b>{{ bookingRequestCount.count }}</b></span></v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
              </v-card>
        </v-menu>

      </v-toolbar>
      <v-card
        class="mx-auto"
        :max-width="!$vuetify.breakpoint.mobile ? '96vw' : '100%'"
        :style="{
          'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
          'border-radius': !$vuetify.breakpoint.mobile ? '25px' : '0px',
        }"
      >
        <v-card-text
          :class="!$vuetify.breakpoint.mobile ? '' : 'px-0'"
          :style="{
            height: !$vuetify.breakpoint.mobile ? '94vh' : '86dvh',
            'overflow-y': 'auto',
          }"
        >
          <v-expand-transition>
            <div v-if="toggleView == 0">
              <BookingTable />
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-if="toggleView == 1">
              <BookingRequest />
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-if="toggleView == 2">
              <ManageContainers />
            </div>
          </v-expand-transition>
          <!-- <v-expand-transition>
            <div v-if="toggleView == 3">
              <ManageTransport />
            </div>
          </v-expand-transition> -->
          <v-expand-transition>
            <div v-if="toggleView == 3">
              <ManageFiles />
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-if="toggleView == 4">
              <BookingException />
            </div>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-card>

    <v-dialog
      v-model="createBookingModal"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <BookingModal
        :bookingObj="booking"
        :related="organisations"
        :relatedLoading="loadingOrganisations"
        @close="(createBookingModal = false), (booking = {})"
      />
    </v-dialog>
  </div>
</template>

<script>

import BookingException from "./BookingException.vue";
import BookingModal from "./BookingModal.vue";
import BookingRequest from "./BookingRequest.vue";
import BookingTable from "./BookingTable.vue";
import dateFormat from "dateformat";
import ManageContainers from "./ManageContainers.vue";
import ManageFiles from "./ManageFiles.vue";
// import ManageTransport from "./ManageTransport.vue";
export default {
  components: {
    BookingException,
    BookingModal,
    BookingRequest,
    BookingTable,
    ManageContainers,
    ManageFiles,
    // ManageTransport,
  },
  data: () => ({
    bookings: {
      total: 0,
      data: []
    },
    bookingRequestCount: {count: 0},
    booking: {},
    bookingStatus: [
      { name: "Draft", icon: "edit_note", color: "#F57C00" },
      { name: "Pending", icon: "pending", color: "#009688" },
      { name: "Requested", icon: "verified", color: "#2196F3" },
      { name: "Confirmed", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Ghost", icon: "help", color: "#607D8B" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" }
    ],
    createBookingModal: false,
    customers: [],
    bookingsKey: 0,
    expanded: [],
    filteredResults: [],
    filters: {},
    toggles: [
      {name: "Bookings", icon: "confirmation_number", num: 0},
      {name: "Booking Requests", icon: "verified", num: 1},
      {name: "Containers", icon: "widgets", num: 2},
      {name: "Files", icon: "description", num: 3},
      {name: "Exceptions", icon: "warning", num: 4},
    ],
    headers: [
      {
        text: "Action",
        align: "center",
        sortable: false,
        value: "action",
        width: "50px",
      },
      {
        text: "Status",
        value: "state",
        align: "center",
        width: "75px",
        filterable: true,
      },
      {
        text: "Order No",
        value: "orderNo",
        align: "center",
        filterable: true,
      },
      {
        text: "Carrier Ref. No",
        value: "carrierReferenceNumber",
        align: "center",
        filterable: false,
      },

      // {
      //     text: 'Vessel',
      //     value: 'vessel.name',
      //     align: 'center',
      // },
      {
        text: "Shipper",
        value: "shipperName",
        align: "left",
        filterable: true,
      },
      {
        text: "Consignee",
        value: "consigneeName",
        align: "left",
        filterable: true,
      },
      {
        text: "Carrier",
        value: "shippingLineName",
        align: "left",
        filterable: true,
      },
      {
        text: "POL",
        value: "portOfLoadValue",
        align: "center",
        filterable: true,
      },
      // {
      //     text: 'POD',
      //     value: 'portOfDischargeValue',
      //     align: 'center',
      //     filterable: true
      // },
      {
        text: "Destination",
        value: "finalDestinationValue",
        align: "center",
        filterable: true,
      },
      {
        text: "Vessel Legs",
        value: "bookingVesselLegs",
        align: "center",
      },
      {
        text: "Containers",
        value: "numContainers",
        align: "center",
      },
    ],
    loading: false,
    loadingContainers: false,
    loadingOrganisations: false,
    loadingConsigneeInstruction: false,
    organisations: {
      total: 0,
      data: [],
    },
    page: 1,
    params: {
      // limit: 18,
      // offset: 0,
      search: null,
    },
    profiles: {
      total: 0,
      data: [],
    },
    sortBy: "firstLegVesselName",
    sortDesc: false,
    shipmentDialog: false,
    shipment: {},
    statusOverview: [],
    toggleView: 0,
  }),
  watch: {
    // async page(value) {
    //   this.params.offset = (value - 1) * this.params.limit;
    //   await this.getBookings();
    // },
    // "params.limit": {
    //   immediate: true,
    //   async handler() {
    //     await this.getBookings();
    //   },
    // },
    // sortBy(value) {
    //   this.params.sortBy = value;
    //   this.getBookings();
    // },
    // sortDesc(value) {
    //   this.params.sortDesc = value;
    //   this.getBookings();
    // },
    "shipment.customerId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getConsigneeProfiles(val);
        }
      },
    },
  },
  computed: {
    countContainers() {
      return this.filteredResults
        .map((x) => x.numContainers)
        .reduce((a, b) => a + b, 0);
    },
    filterProfiles() {
      let result = this.profiles.data;
      if (this.shipment && this.shipment.pod) {
        result = result.filter(
          (x) =>
            x.consigneeProfilePorts.filter((y) => y.code == this.shipment.pod)
              .length > 0
        );
      }
      if (result.length == 0) {
        result = this.profiles.data;
      }
      return result;
    },
  },
  created() {
    // this.load();
  },
  async mounted() {
    this.resetFilters();
    this.bookingRequestCount = await this.$API.getBookingRequestCount()
  },
  methods: {
    getView() {
      return this.toggles.find((x) => x.num == this.toggleView);
    },

    addBooking() {
      this.booking = {
        status: "Draft",
        transportType: "Container",
        bookingContainerItems: [],
        bookingCargoItems: [],
        bookingPaymentDetails: []
      };
      this.createBookingModal = true;
    },
    addToFilter(value, field) {
      if (this.filters[field].includes(value)) {
        this.filters[field] = this.filters[field].filter((x) => x != value);
      } else {
        this.filters[field].push(value);
      }
      this.calculateFilteredResults();
    },
    calculateFilteredResults() {
      let result = [...this.bookings.data];
      let keys = Object.keys(this.filters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.filters[key] && this.filters[key].length > 0) {
          result = result.filter((x) => this.filters[key].includes(x[key]));
        }
      }
      this.filteredResults = result;
    },
    expandBooking(item) {
      let findIndex = this.expanded.findIndex((x) => x.id == item.id);
      if (findIndex == -1) {
        if (!item.bookingContainers) {
          item.bookingContainers = [];
          this.getBookingContainers(item);
        }
        this.expanded = [item];
      } else {
        this.expanded.splice(findIndex, 1);
      }
    },
    filterByVessel(vesselId) {
      if (this.filters.vesselId && this.filters.vesselId.includes(vesselId)) {
        this.filters.vesselId = this.filters.vesselId.filter(
          (x) => x != vesselId
        );
      } else {
        this.filters.vesselId = [vesselId];
      }

      this.calculateFilteredResults();
    },
    formatHeaderName(value, field, data) {
      switch (field) {
        case "vessel.name":
          return data.vessel.name;
        case "consignee.name":
          return data.consignee.friendlyName ?? data.consignee.name;
        case "shipper.name":
          return data.shipper.friendlyName ?? data.shipper.name;
        case "shippingLine.name":
          return data.shippingLine.friendlyName ?? data.shippingLine.name;
        default:
          return value;
      }
    },
    async getBookingContainers(item) {
      this.loadingContainers = true;
      item.bookingContainers = await this.$API.getBookingContainersBasic(
        item.id
      );
      this.loadingContainers = false;
    },
    headerOptions(value) {
      let result = [
        ...new Set(
          this.bookings.data
            .filter((x) => x[value])
            .map((x) => ({
              name: this.formatHeaderName(x[value], value, x),
              value: x[value],
            }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      return result;
    },
    async load() {
      this.getBookings();
      await this.getRelations();
    },

    async getBookings() {
      this.loading = true;
      this.statusOverview = await this.$API.getBookingOverviewStatus({});
      this.bookings = await this.$API.getBookingOverview({
        // params: this.params
      });
      // console.log(this.bookings)
      // params: this.params
      this.calculateFilteredResults();
      this.loading = false;
    },
    async getRelations() {
      this.loadingOrganisations = true;
      this.organisations = await this.$API.getRelationBasic({
        // params: this.params,
      });
      this.loadingOrganisations = false;
    },
    async getConsigneeProfiles(id) {
      this.loadingConsigneeInstruction = true;
      this.profiles = await this.$API.listCustomerConsigneeProfile(id, {});
      this.loadingConsigneeInstruction = false;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatTime(time) {
      let result = null;
      if (time) {
        result = new Date(time)
          .toISOString()
          .replace(/T/, " ") // replace T with a space
          .replace(/\..+/, "")
          .substr(0, 16);
      }
      return result;
    },
    formatUtcTime(time) {
      let result = null;
      if (time) {
        result = new Date(time)
          .toISOString()
          .replace(/T/, " ") // replace T with a space
          .replace(/\..+/, "");
      }
      return result;
    },
    getIcon(state) {
      switch (state) {
        case "Confirmed":
          return "check_circle_outline";
        case "Declined":
          return "highlight_off";
        case "Requested":
          return "pending";
        case "Cancelled":
          return "block";
        case "Replaced":
          return "sync";
        case "Amended":
          return "edit";
      }
    },
    getIconColor(state) {
      switch (state) {
        case "Confirmed":
          return "green";
        case "Declined":
          return "red";
        case "Requested":
          return "blue";
        case "Cancelled":
          return "red";
        case "Replaced":
          return "orange";
        case "Amended":
          return "grey";
      }
    },
    resetFilters() {
      this.filters = {};
      let keys = this.headers.map((x) => x.value);
      keys.forEach((x) => {
        this.filters[x] = [];
      });
      this.calculateFilteredResults();
    },
    statusColor(status) {
      let color = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return color ? color.color : "grey";
    },
    statusIcon(status) {
      let find = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return find ? find.icon : "";
    },
    statusCount(status) {
      let find = this.statusOverview.find(
        (x) => x.state == status.toLowerCase()
      );
      return find ? find.count : 0;
    },
    viewBooking(item) {
      this.$router.push({
        path: "/booking/" + item.systemReference,
      });
    },
  },
};
</script>

<style scoped>
.toolbarHead :is(.v-toolbar__content) {
  height: 50px;

}
.toolbarHeadMobile :is(.v-toolbar__content) {
  padding: 10px 5px 10px 5px ;
  height: 45px;
}

.selectedToggle {
  background-color: var(--v-greyBase-lighten1) !important;
}
</style>
